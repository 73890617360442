import React, { useState, useRef, useEffect } from "react";
import "./Jobs.scss";
import "./PreviewJob.scss";
import filterLogo from "../../assets/images/Group.png";
import circle from "../../assets/images/circle.png";
import money from "../../assets/images/money.png";
import calendar from "../../assets/images/calendar.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../Layout/Spinner";
import { useLocation } from "react-router-dom";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../actions/userActions";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { MdOutlineCheckCircle } from "react-icons/md";
import { State } from "country-state-city";
import PropagateLoader from "react-spinners/PropagateLoader";
import home from "../../assets/images/home.png";
import game from "../../assets/images/game.png";
import arrowup from "../../assets/images/Arrowup.jpg";
import { SlLocationPin } from "react-icons/sl";
import { BiBriefcaseAlt } from "react-icons/bi";
import { RxCounterClockwiseClock, RxCross2 } from "react-icons/rx";
import { FiUsers } from "react-icons/fi";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Dropdown from "./Dropdown";
import "./Filter.css";
import { RiArrowDownSFill, RiArrowDownSLine } from "react-icons/ri";
import { marks4 } from "../EmployerNew/utils/constants";
import { input } from "@material-tailwind/react";
import citiesData from "../Employer/citiesData.json";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { useParams } from 'react-router-dom';

function Internships() {

  const { filterType } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState([1, 100]); // set initial value to 50
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const userType = localStorage.getItem("userType");


  const initial = {
    opportunityType: "Internship",
    profile: filterType && filterType.startsWith('profile_') ? [filterType.split('_')[1]] : [],
    location: filterType && filterType.startsWith('location_') ? [filterType.split('_')[1]] : [],
    jobType: filterType === 'workFromHome'? true: false,
    partTime: false,
    fullTime: false,
    minStipend: 0,
    startFrom: "",
    maxduration: [],
    keyword: "",
};
const [filter, setFilter] = useState(initial);

  const handleFilter = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // If the event target is a checkbox, handle its state
      const updatedJobType = [...filter.jobType];
      if (checked) {
        updatedJobType.push(name);
        } else {
        const index = updatedJobType.indexOf(name);
        if (index > -1) {
        updatedJobType.splice(index, 1);
        }
      }

      setFilter((prevFilter) => ({
        ...prevFilter,
        jobType: updatedJobType,
      }));
    } else {
      // For other input types, handle normally
      setFilter({
        ...filter,
        [name]: value,
      });
    }
  };
 
  /******************************Filter Option End**********************************/

  // SEARCH OPTION :

  const [search, setSearch] = useState({
    title: state && state.title ? state.title : "",
    location: state && state.location ? state.location : "",
  });

  const handleChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };
  const [totalItem, setTotalItem] = useState(0);
  
  const handleSearch = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post("/api/user/findInternships", {
        page: currentPage, // Add current page
        pageSize: 25, // Display 25 jobs per page
        filter: filter,
      });

      const activeJobs = data.jobs.filter((job) => job.active === true);
  
      setLoading(false);
      setJobData(activeJobs);
      setTotalPages(data.totalPages);
      setTotalItem(data.totalItems);
    } catch (error) {
      setLoading(false);
      // Handle errors more gracefully
      console.error("Error while fetching jobs:", error);
      // Show error message to the user
      toast.error("Failed to fetch jobs. Please try again later.", {
        style: {
          padding: "18px",
        },
      });
    }
  };
  
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  /*************************Search Option End********************************/

  const handleSliderChange = (event, newValue) => {
    // Update the rangeSalary filter based on slider values
    setFilter({
      ...filter,
      rangeSalary: newValue,
    });
  };

  const dropdownMin = (value) => {
    // Ensure that the minimum value is not greater than the current maximum value
    const newMinValue = Math.min(value, filter.rangeSalary[1]);

    // Update the rangeSalary filter based on slider values
    setFilter({
      ...filter,
      rangeSalary: [newMinValue, filter.rangeSalary[1]],
    });
  };

  const dropdownMax = (value) => {
    // Ensure that the maximum value is not less than the current minimum value
    const newMaxValue = Math.max(value, filter.rangeSalary[0]);

    // Update the rangeSalary filter based on slider values
    setFilter({
      ...filter,
      rangeSalary: [filter.rangeSalary[0], newMaxValue],
    });
  };



  const stepValues = Array.from({ length: 11 }, (_, i) => i * 10 * 1000);

  const formatNumberInK = (value) => {
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}k`;
    }
    return value.toString();
  };

  const generateStepLabels = () => {
    return stepValues.map((value) => ({
      value,
      label: "",
    }));
  };

  const filtersBox = useRef(null);

  const toggleFiltersBox = () => {
    filtersBox.current.classList.toggle("open");
  };

  const { user } = useSelector((state) => state.user);

  const [jobData, setJobData] = useState("");
  const [orgData, setOrgData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filled, setFilled] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [isData, setIsData] = useState(false);

  const [profiles, setProfiles] = useState([]);
  useEffect(() => {
    async function getAllProfiles() {
      const response = await axios.get("/api/data/profiles");
      const data = await response.data;
      if (data) setProfiles(data);
    }

    getAllProfiles();
    dispatch(loadUser());
  }, []);




  // useEffect(() => {
  //     handleSearch();
  //     window.scrollTo(0, 0);
  // }, [filter.profile, filter.location, filter.jobType, filter, currentPage]);
  useEffect(() => {
    setCurrentPage(1);
  }, [filter.profile, filter.location, filter.jobType, filter]);
  
  // Effect to handle search when currentPage or filters change
  useEffect(() => {
    handleSearch();
    window.scrollTo(0, 0);
  }, [currentPage, filter]);





 // OTHER FUNCTION: 


 
//  const openPreviewPageInNewTab = (state) => {
//   const data = state.data;
//   window.open(`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.employer.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase());
// };
const openPreviewPageInNewTab = (state) => {
  const data = state.data;
  const url = `/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.employer.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase();
  return url
};

const formatNumberWithCommas = (number) => {
  // Convert number to string
  let strNumber = number.toString();

  // Split the string into parts (crores, lakhs, thousands, and hundreds)
  let crores = strNumber.slice(0, -7);
  let lakhs = strNumber.slice(-7, -5);
  let thousands = strNumber.slice(-5, -3);
  let hundreds = strNumber.slice(-3);

  // Join the parts with commas
  return crores + (crores ? "," : "") + lakhs + (lakhs ? "," : "") + thousands + "," + hundreds;
};

const [heading, setHeading] = useState(false);

const handleTitle = () => {
  setHeading(!heading);
};
const calculateDaysDifference = (startDate) => {
  const start = new Date(startDate);
  const end = new Date();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = end - start;

  // Convert the difference to days
  const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

  // Set the state with the calculated difference
  return differenceInDays;
};

 const handleSaveJob = async (jobId) => {
  try {
    const { data } = await axios.get(`/api/user/saveJob/${jobId}`, {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    });
    if (data.success) {
      setFilled(!filled);
      window.location.reload();
    }
  } catch (error) {}
};




const handleRemoveJob = async (jobId) => {
  try {
    const { data } = await axios.get(`/api/user/removeJob/${jobId}`, {
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    });

    if (data.success) {
      setFilled((prevFilled) => !prevFilled);
      window.location.reload();
    }
  } catch (error) {}
};


const [viewMore, setViewMore] = useState(false);
const [openCalendar, setOpenCalendar] = useState(false);
const handleCheckBox = (data) => {
  if (filter?.jobType.includes(data)) {
    setFilter((prev) => ({ ...prev, jobType: prev.jobType.filter((i) => i !== data) }));
  } else {
    setFilter({ ...filter, jobType: [...filter.jobType, data] });
  }
};

const calendarRef = useRef();
const handleClickOutside = (event) => {
  if (calendarRef.current && !calendarRef.current.contains(event.target)) {
    setOpenCalendar(false);
  }
};


const [isSearchCalled, setIsSearchCalled] = useState(false);
const handleKeywordCall = (e) => {
  e.preventDefault();
  setFilter({ ...filter, keyword: keyword });
  setIsSearchCalled(false);
  setIsData(false);
};
const handleSearch2 = async () => {
  setLoading(true)
  setIsSearchCalled(searchValue.trim() !== '')
  // setCurrentPage(1);
  try {
    const response = await axios.post("/api/user/findFilteredInternship", {
      search: {
        profile: searchValue,
      },
      // page: currentPage, // include currentPage in the request
      page: 1, // include currentPage in the request
      pageSize: 25
    });
    console.log(response.data,"response data")
    setLoading(false);
    setJobData(response.data?.data);
    setTotalPages(response.data.totalPages);
    setIsData(searchValue.trim() !== '' && response.data?.data.length > 0);
  } catch (error) {
    setLoading(false);
    console.error("Error while fetching job data:", error);
    // Handle errors
  }
};

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    if (!value.trim()) {
      setIsData(false); // Reset isData to false when searchValue is empty
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch2();
    }
  };
  // useEffect(() => {
  //   const delayDebounceFn = setTimeout(() => {
  //     handleSearch2();
  //   }, 300); // Adjust the delay as needed

  //   return () => clearTimeout(delayDebounceFn);
  // }, [searchValue]);
  useEffect(() => {
    if (searchValue.trim() !== '') {
      const delayDebounceFn = setTimeout(() => {
        handleSearch2();
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchValue]);
  return (
    <>
      <div className="container-fluid matched-jobs ">
        <div className="jobbox container ">
          <div className="row RowMargin " style={{ maxWidth: "1050px" }}>
            <div style={{ display: "flex", flexWrap: "wrap", color: "#494949", fontSize: "15px", marginLeft: "10px" }}>
              <p style={{ marginRight: "4px", cursor: "pointer" }} onClick={() => navigate("/")}>
                Home
              </p>
              <IoIosArrowForward style={{ marginRight: "4px", marginTop: "3px" }} />
              <p style={{ marginRight: "4px", cursor: "pointer" }}>Internships</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 mx-auto" style={{ marginTop: "84px", maxWidth: "350px" }}>
              <div className="filterbox  py-0 adjustfilterboxheight" style={{ height: "83vh", boxShadow: "none" }}>
                <div className="filter-container">
                  <div className="title">
                    <img src={filterLogo} alt="Account" style={{ width: "25px" }} /> Filters
                  </div>

                  <div className="body">
                    {/* Profile */}
                    <div className="filter-category">
                      <div>Internship Role</div>
                      {/* <Dropdown
                        multiple={true}
                        placeholder="e.g. Marketing"
                        category="profile"
                        actual={filter}
                        setActual={setFilter}
                        options={profiles}
                      /> */}
                      <input
                        type="text"
                        placeholder="i.e. Web Developer"
                        value={searchValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown} // Call handleSearch on Enter key press
                      />
                    </div>

                    {/* Location */}
                    <div className="filter-category">
                      <div>Location</div>
                      <Dropdown
                        multiple={true}
                        placeholder="e.g. Delhi"
                        category="location"
                        actual={filter}
                        setActual={setFilter}
                        setIsSearchCalled={setIsSearchCalled}
                        setIsData={setIsData}
                        // options={citiesData["India"]}
                      />
                    </div>

                    {/* type */}
                    {/* <div className="checkbox-container">
                      <div className="category d-flex align-items-center gap-3">
                        <input type="checkbox" checked={filter.jobType} onChange={() => setFilter({ ...filter, jobType: !filter.jobType })} />
                        <span>Work from home</span>
                      </div>
                      <div className="category d-flex align-items-center gap-3">
                        <input type="checkbox" checked={filter.partTime} onChange={() => setFilter({ ...filter, partTime: !filter.partTime })} />
                        <span>Part-time</span>
                      </div>
                      <div className="category d-flex align-items-center gap-3">
                        <input type="checkbox" checked={filter.fullTime} onChange={() => setFilter({ ...filter, fullTime: !filter.fullTime })} />
                        <span>Full-time</span>
                      </div>
                    </div> */}
                    <div className="checkbox-container">
                      <div className="category d-flex align-items-center gap-3">
                        <input type="checkbox" checked={filter.jobType} onChange={() => {
                          setFilter({ ...filter, jobType: !filter.jobType });
                          setIsSearchCalled(false);
                          setIsData(false);
                      
                        }} />
                        <span>Work from home</span>
                      </div>
                      <div className="category d-flex align-items-center gap-3">
                        <input type="checkbox" checked={filter.partTime} onChange={() => {
                          setFilter({ ...filter, partTime: !filter.partTime });
                          setIsSearchCalled(false);
                          setIsData(false);
                          
                        }} />
                        <span>Part-time</span>
                      </div>
                      <div className="category d-flex align-items-center gap-3">
                        <input type="checkbox" checked={filter.fullTime} onChange={() => {
                          setFilter({ ...filter, fullTime: !filter.fullTime });
                          setIsSearchCalled(false);
                          setIsData(false);
                          
                        }} />
                        <span>Full-time</span>
                      </div>
                    </div>

                    {/* Minimum stipend */}
                    <div className="filter-category">
                      <div>Desired minimum monthly stipend (₹)</div>
                      <Slider
                        aria-label="stipend"
                        value={filter.minStipend}
                        getAriaValueText={(value) => value}
                        step={5000}
                        marks={marks4}
                        min={0}
                        max={25000}
                        // onChange={(e, value) => setFilter({ ...filter, minStipend: value })}
                        onChange={(e, value) => {setFilter({ ...filter, minStipend: value }) ; setIsSearchCalled(false);
                          setIsData(false);}}
                      />
                    </div>

                    <span className="instruction" onClick={() => setViewMore(!viewMore)}>
                      View {viewMore ? "less" : "more"} filters{" "}
                      <RiArrowDownSLine size={20} style={{ transform: `${viewMore ? "rotate(180deg)" : "rotate(0deg)"}` }} />
                    </span>

                    {viewMore && (
                      <>
                        <div ref={calendarRef} className="filter-category" style={{ position: "relative" }}>
                          <label htmlFor="calendar">Starting from (or after)</label>
                          <div className="parent-dropdown2">
                            {filter.startFrom ? (
                              <div onClick={() => setOpenCalendar(!openCalendar)} className="d-flex align-items-center gap-2 p-2 flex-wrap">
                                <span className="dates">
                                  {filter.startFrom}
                                  {/* <RxCross2 onClick={() => setFilter({ ...filter, startFrom: "" })} /> */}
                                  <RxCross2 onClick={() => {setFilter({ ...filter, startFrom: "" });setIsSearchCalled(false);
                          setIsData(false);}} />
                                </span>
                              </div>
                            ) : (
                              <input
                                type="date"
                                value={filter.startFrom}
                                // onChange={(e) => setFilter({ ...filter, startFrom: e.target.value })}
                                onChange={(e) => {setFilter({ ...filter, startFrom: e.target.value });setIsSearchCalled(false);
                          setIsData(false);}}
                                // onClick={() => setOpenCalendar(!openCalendar)}
                                style={{ border: "none", outline: "none", fontSize: "14px" }}
                                placeholder="Choose Date"
                              />
                            )}
                            {/* {openCalendar && (
                              <div id="calendar-container" style={{ position: "absolute", right: "0px", top: "50px", width: "100%", zIndex: "100" }}>
                                <Calendar
                                  minDate={new Date()}
                                  onChange={(value) => {
                                    setFilter({ ...filter, startFrom: value.toDateString() });
                                    setOpenCalendar(false);
                                  }}
                                />
                              </div>
                            )} */}
                          </div>
                        </div>

                        <div className="filter-category">
                          <div>Max. duration (months)</div>
                          <Dropdown
                            placeholder="Choose duration"
                            multiple={false}
                            category="maxduration"
                            actual={filter}
                            setActual={setFilter}
                            options={["1 months", "2 months", "3 months", "4 months", "5 months", "6 months"]}
                            setIsSearchCalled={setIsSearchCalled}
                            setIsData={setIsData}
                          />
                          {/* <input value={filterInput.skills} name="skills" onChange={handleChange} type="text" placeholder="e.g. Java" /> */}
                        </div>
                      </>
                    )}

                    {/* <span onClick={() => setFilter({
                       opportunityType: "Internship",
                       profile:  [],
                       location: [],
                       jobType: false,
                       partTime: false,
                       fullTime: false,
                       minStipend: 0,
                       startFrom: "",
                       maxduration: [],
                       keyword: "",
                    })} className="ml-auto instruction">
                      Clear all
                    </span> */}
                    <span onClick={() => {setFilter({
                       opportunityType: "Internship",
                       profile:  [],
                       location: [],
                       jobType: false,
                       partTime: false,
                       fullTime: false,
                       minStipend: 0,
                       startFrom: "",
                       maxduration: [],
                       keyword: "",
                    });
                    setIsSearchCalled(false);
                          setIsData(false);}} className="ml-auto instruction">
                      Clear all
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    justifyItems: "center",
                    marginTop:'-15px'
                  }}
                >
                  <hr style={{ width: "41%", height: "0.01px", color: "gray" }} />
                  <p className="mx-3 mt-3" style={{ fontSize: "13px", color: "gray" }}>
                    OR
                  </p>
                  <hr style={{ width: "41%", height: "0.01px", color: "gray" }} />
                </div>
                <div className="filter-container">
                  <div className="title" style={{ fontSize: "18px" ,marginBottom:'35px'}}>
                    Keyword Search
                  </div>

                  <div className="body" style={{ paddingBottom: viewMore ? "50px" : "0px" , marginTop:'-10px'}}>
                    {/* Keyword */}
                    <form onSubmit={handleKeywordCall}>
                      <input
                        minLength={3}
                        id="keyword"
                        type="text"
                        placeholder="e.g. Design, Mumbai, Infosys"
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                        style={{ fontSize: "14px" , width:"100%", height:"100%",border:"1px solid #ddd",outline:'none',paddingTop:"6px", paddingBottom:"6px",paddingLeft:'10px', paddingRight:'10px'}}
                      />
                      <button type="submit" style={{height:'100%',color:"white",backgroundColor:"#00a5ec",border:"1px solid #00a5ec",paddingTop:"0px",paddingBottom:"0px",paddingLeft:"10px",paddingRight:"10px",borderTopLeftRadius:"4px",borderTopRightRadius:"4px",transition:"all 0.3s"}}>
                        <FaMagnifyingGlass />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="fixed-header">
                <h3 style={{ cursor: "pointer" }} onClick={handleTitle} className="title my-5">
                  {/* {loading? "":totalItem} {filter.jobType?" Work From Home": ""} {filter.profile? filter.profile: ""} Internship {filter.location && filter.location.length > 0 ? " in "+ filter.location: ""} {heading ? <IoIosArrowUp /> : <IoIosArrowDown />}{" "} */}
                  {/* {loading? "":jobData && jobData.length} {filter.jobType?" Work From Home": ""} {isData ? jobData[0].profile : (filter.profile ? filter.profile : "")} Internship {filter.location && filter.location.length > 0 ? " in "+ filter.location: ""} {heading ? <IoIosArrowUp /> : <IoIosArrowDown />}{" "} */}
                  {loading ? "" : isSearchCalled ? jobData?.length : totalItem}  {filter.jobType?" Work From Home": ""} {isData ? jobData[0]?.profile : (filter.profile ? filter?.profile : "")} Internship {filter.location && filter.location.length > 0 ? " in "+ filter.location: ""} {heading ? <IoIosArrowUp /> : <IoIosArrowDown />}{" "}
                </h3>
                {heading ? (
                  <>
                    <p style={{ marginTop: "-20px", fontSize: "13px", color: "#494949", textAlign: "center" }}>
                    Recent internship vacancies and openings across the globe
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>

              {loading ? (
                <>
                  <div className="my-auto" style={{ textAlign: "center", marginLeft: "-10px" }}>
                    <p style={{ color: "#4B92C8", fontWeight: "500", marginTop: "170px", fontSize: "20px", marginLeft: "17px", marginBottom: "5px" }}>
                      Loading..
                    </p>
                    <PropagateLoader
                      color={"#4B92C8"}
                      loading={true}
                      size={15}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                      
                    />
                  </div>
                </>
              ) : (
                <>
                  {jobData && jobData.length != 0 ? (
                    <>
                      <div className="content-below-header">
                        <div className="alljobdetails pb-2">
                          {jobData.map((data, index) => {
                              return (
                                <>
                                  <div className="cards my-4">
                                    <div className="card py-4 px-5">
                                      <div className="cardHeader">
                                        {/*NEW CODE START HERE*/}
                                        <div
                                          className="mt-3  wfm123"
                                          style={{
                                            width: "124px",
                                            borderRadius: "5px",
                                            fontSize: "13px",
                                            textAlign: "center",
                                            fontWeight: "400",
                                            padding: "3px",
                                            color: "black",
                                            border: "solid 1px #D1D1D1",
                                          }}
                                        >
                                          <img src={arrowup} alt="" style={{ marginRight: "5px", height: "17px", marginTop: "-4px" }} />
                                          Actively hiring
                                        </div>
                                        <div className="row">
                                          <div className="col-md-9 col-8">
                                            
                                          <a style={{ textDecoration: "none", color: "black" }} href={`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.employer.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase()}>
                                            
                                            <h3
                                              style={{ fontSize: "19px", textAlign: "start", cursor: "pointer" }}
                                              className="title pt-3"
                                            >
                                              {data.profile}
                                            </h3></a>
                                            <h4 onClick={() => navigate('/company-detail', { state: data.employer })}
                                              style={{ fontSize: '15px', color: 'gray', cursor: "pointer"} } className="companyname pt-3">
                                              {data.employer.organisationName}
                                            </h4>
                                          </div>
                                          <div className="col-3 text-end ">
                                          {data.employer.organisationAvatar && (
                                              <img
                                                src= {data.employer.organisationAvatar.url}
                                                alt=""
                                                className= "OrgLogoAvatar"
                                               
                                              />
                                            )}
                                          </div>
                                        </div>

                                        <div className="content mb-5" style={{ marginTop: "20px" }}>
                                          <span>
                                            {data.jobType === "Remote" ? (
                                              <>
                                                <img src={home} alt="Engineering" style={{ width: "14px", marginTop: "-2px" }} />
                                                <span className="wfm1 ml-2" style={{ fontSize: "14px", marginRight: "20px", marginTop: "-2px" }}>
                                                  Work From Home
                                                </span>
                                              </>
                                            ) : (
                                              <>
                                                {data.city.map((data) => (
                                                  <>
                                                    <SlLocationPin
                                                      style={{ color: "gray", fontSize: "15px", marginBottom: "3px", marginTop: "-2px" }}
                                                    />
                                                    <span style={{ fontSize: "15px", marginRight: "20px", marginTop: "-2px" }} className="wfm1 ml-2">
                                                      {data}
                                                    </span>
                                                  </>
                                                ))}
                                              </>
                                            )}
                                          </span>
                                          <div className=" pt-4 pb-5 " style={{ display: "flex", flexWrap: "wrap", gap: '20px 70px'}}>
                                            <div className="statusBoxes">
                                              <p>
                                                <span>
                                                  <img src={circle} alt="" style={{ width: "16px" }} />
                                                </span>
                                                <span className="mx-2 heeading" style={{ fontWeight: "500" }}>
                                                  START DATE
                                                </span>
                                              </p>
                                              <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                {data.startDate == "Immediately (within next 30 days)" ? <>Immediately</> : <>{data.startDate}</>}
                                              </h3>
                                            </div>
                                            <div className="statusBoxes">
                                              <p>
                                                <span>
                                                  <img src={calendar} alt="" style={{ width: "16px" }} />
                                                </span>
                                                <span className="mx-2 heeading" style={{ fontWeight: "500" }}>
                                                  DURATION
                                                </span>
                                              </p>
                                              <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                {data.duration} {(data?.durationType==="month"&&data.duration>1?"months":data?.durationType) || "months"}
                                              </h3>
                                            </div>

                                            <div className="statusBoxes">
                                              <p>
                                                <span>
                                                  <img src={money} alt="" style={{ width: "16px" }} />
                                                </span>
                                                <span className="mx-2 heeading" style={{ fontWeight: "500" }}>
                                                  STIPEND
                                                </span>
                                              </p>
                                              {!data.salary && data.salary == 0 ? (
                                                <>
                                                  <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                    Unpaid
                                                  </h3>
                                                </>
                                              ) : (
                                                <>
                                                  <h3 className="wfm123" style={{ fontSize: "14px" }}>
                                                    ₹ {formatNumberWithCommas(data.salary)} /month
                                                  </h3>
                                                </>
                                              )}
                                            </div>

                                            <div className="pr-1 pb-4">
                                              <span>
                                                <span>
                                                  {calculateDaysDifference(data.datePosted).toFixed(0) < 6 ? (
                                                    <>
                                                      {calculateDaysDifference(data.datePosted).toFixed(0) == 0 ? (
                                                        <span className=" post1" style={{ backgroundColor: "#E1FFE1", color: "green" }}>
                                                          <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted just now
                                                        </span>
                                                      ) : (
                                                        <span className=" post1" style={{ backgroundColor: "#E1FFE1", color: "green" }}>
                                                          <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted{" "}
                                                          {calculateDaysDifference(data.datePosted).toFixed(0)} days ago
                                                        </span>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {calculateDaysDifference(data.datePosted).toFixed(0) > 5 &&
                                                      calculateDaysDifference(data.datePosted).toFixed(0) < 11 ? (
                                                        <>
                                                          <span className=" post1 " style={{ backgroundColor: "#E2F8FF", color: "#0D8CB8" }}>
                                                            <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted{" "}
                                                            {calculateDaysDifference(data.datePosted).toFixed(0)} days ago
                                                          </span>
                                                        </>
                                                      ) : (
                                                        <>
                                                          <span className=" post1 ">
                                                            <RxCounterClockwiseClock style={{ fontSize: "14px", marginTop: "-2px" }} /> Posted{" "}
                                                            {calculateDaysDifference(data.datePosted).toFixed(0)} days ago
                                                          </span>
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </span>{" "}
                                                <span className=" post1" style={{ marginLeft: "10px" }}>
                                                  {data.opportunityType}
                                                </span>
                                                {data.isPartTimeAllowed == "Yes" ? (
                                                  <span className=" post1" style={{ marginLeft: "10px" }}>
                                                    Part time
                                                  </span>
                                                ) : (
                                                  <></>
                                                )}
                                              </span>

                                              <span></span>
                                            </div>
                                          </div>
                                          {/*NEW CODE END HERE*/}
                                        </div>
                                        <hr className="" style={{ height: "0.1px", color: "#C4C4C4", marginTop: "-60px" }}></hr>
                                        <div className="cardbutton ">
                                          <div className="ml-auto text-end" style={{ display: "flex", flexWrap: "wrap" }}>
                                            {/* <button  onClick={() => openPreviewPageInNewTab({ data })} className=" btnview1 ml-auto">
                                              {" "}
                                              View Details
                                            </button> */}
                                            <a  
                                            href={openPreviewPageInNewTab({data})}
                                            rel="noreferrer"
                                            target="_blank"
                                            className=" btnview1 ml-auto"
                                            style={{                   
                                              textDecoration: 'none', // to remove underline
                                              display:'flex',
                                              alignItems:'center'
                                            }}
                                            >
                                              {" "}
                                              View Details
                                            </a>
                                            {user ? (
                                              userType !== "Employer" &&
                                              userType !== null &&
                                              (data.userApplied.includes(user ? user._id : "") ? (
                                                <>
                                                  <p
                                                    style={{
                                                      color: "green",
                                                      fontWeight: "400",
                                                      marginBottom: "-2px",
                                                      marginLeft: "10px",
                                                      marginRight: "10px",
                                                    }}
                                                  >
                                                    <MdOutlineCheckCircle style={{ marginRight: "3px", fontSize: "18px", marginTop: "-2px" }} />
                                                    Applied
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  {/* <button
                                                    onClick={() => navigate("/coverletterpage", { state: data })}
                                                    className="btn btnapply"
                                                    style={{ width: "100px", marginLeft: "20px" }}
                                                  >
                                                    Apply Now
                                                  </button> */}
                                                  <button
                                                      onClick={() => {
                                                        const { phone, city, resume } = user;

                                                        if (!phone && !city && !resume) {
                                                          navigate("/addResume");
                                                        } else if ((phone || city) && !resume) {
                                                          navigate("/addResume");
                                                        } else {
                                                          // navigate("/coverLetterPage", { state: data });
                                                          const jobId = `${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.employer.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase();
                                                          const encodedJobId = encodeURIComponent(jobId);
                                                          navigate(`/coverletterpage/${encodedJobId}`, { state: data });
                                                        }
                                                      }}
                                                      className="btn btnapply"
                                                      style={{
                                                        width: "100px",
                                                        marginLeft: "20px",
                                                      }}
                                                    >
                                                      Apply Now
                                                    </button>
                                                </>
                                              ))
                                            ) : (
                                              <>
                                                <div style={{ textAlign: "center" }}>
                                                  {/* <button onClick={() => navigate("/login")} className="btn btnapply" style={{ marginLeft: "0px" }}> */}
                                                  <button onClick={() => navigate('/login', { state: { from: location.pathname } })} className="btn btnapply" style={{ marginLeft: "0px" }}>
                                                    Apply Now
                                                  </button>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </div>
                      </div>
                      {totalPages>1 && (

                      <div className="pagination" style={{ marginTop: "-20px" }}>
                        <p onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                          <IoIosArrowBack className={currentPage === 1 ? "paginationBtndisabled" : "paginationBtn" } style={{ marginTop: "6px" }} />
                        </p>
                        <span style={{ fontSize: "16px" }}>
                          {currentPage} / {totalPages}
                        </span>
                        <p onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                          <IoIosArrowForward className= {currentPage === totalPages ? "paginationBtndisabled" : "paginationBtn" } style={{ marginTop: "6px" }} />
                        </p>
                      </div>
                      )}

                    </>
                  ) : (
                    <>
                      <div className="text-center mt-5">
                        <i class="fa fa-window-close-o mx-auto" aria-hidden="true" style={{ fontSize: "200px" }}></i>
                        <h1>Internship not Found</h1>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
      position="top-center"
      autoClose={10000}
      />
    </>
  );
}

export default Internships;
