import React, { useState, useEffect, useRef } from 'react';
import Card from './Card';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function Recommended({ data }) {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const [settings, setSettings] = useState({
    speed: 500,
    slidesToScroll: 1,
    // infinite: true,
    infinite: false,
    slidesToShow: 4, // Default value, can be overridden
    beforeChange: (oldIndex, newIndex) => handleBeforeChange(newIndex),
    afterChange: index => handleAfterChange(index),
  });

  useEffect(() => {
    // Function to update slidesToShow based on window size
    const updateSlidesToShow = () => {
      const pageSize = window.innerWidth;
      let slidesToShow, slidesToScroll;
      if (pageSize >= 1300) {
        slidesToShow = 4; // Show 4 slides for large screens
        slidesToScroll =4;
      } else if (pageSize >= 992) {
        slidesToShow = 3; // Show 3 slides for medium screens
        slidesToScroll =3;
      } else if (pageSize >= 768) {
        slidesToShow = 2; // Show 2 slides for small screens
        slidesToScroll =2;
      } else {
        slidesToShow = 1; // Show 1 slide for extra small screens
        slidesToScroll =1;
      }
      // Update the state with the new value of slidesToShow
      setSettings(prevSettings => ({
        ...prevSettings,
        slidesToShow,
        slidesToScroll
      }));
    };

    // Call the function initially to set the correct value
    updateSlidesToShow();

    // Listen to the window resize event to update the slidesToShow value dynamically
    window.addEventListener('resize', updateSlidesToShow);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', updateSlidesToShow);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on mount


  const thumbRef = useRef(null);

  const sliderRef = useRef(null);
  const slider = useRef(null);

  // const handlePrevButtonClick = () => {
  //   // const newIndex = Math.max(currentCardIndex - 1, 0);
  //   const newIndex = Math.max(currentCardIndex - settings.slidesToScroll, 0);
  //   setCurrentCardIndex(newIndex);
  //   slider?.current?.slickPrev();
  // };

  // const handleNextButtonClick = () => {
  //   // const newIndex = Math.min(currentCardIndex + 1, data.length - 1);
  //   const newIndex = Math.min(currentCardIndex + settings.slidesToScroll, data.length - settings.slidesToScroll);
  //   setCurrentCardIndex(newIndex);
  //   slider?.current?.slickNext();
  // };

  // const updateThumbPosition = () => {
  //   const totalItems = data.length;
  //   // const visibleItems = 4;
  //   const visibleItems = settings.slidesToScroll;
  //   const maxThumbLeft = 63;
  //   const thumbLeft = Math.min(
  //     (currentCardIndex / (totalItems - visibleItems)) * maxThumbLeft,
  //     maxThumbLeft
  //   );
  //   thumbRef.current.style.left = `${thumbLeft}%`;
  // };

  const handleBeforeChange = (newIndex) => {
    setCurrentCardIndex(newIndex);
  };

  const handleAfterChange = (index) => {
    setCurrentCardIndex(index);
    updateThumbPosition(index);
  };

  const handlePrevButtonClick = () => {
    slider.current.slickPrev();
  };

  const handleNextButtonClick = () => {
    slider.current.slickNext();
  };

  const updateThumbPosition = (index) => {
    const totalItems = data.length;
    const visibleItems = settings.slidesToShow;
    const maxThumbLeft = 63;
    const thumbLeft = Math.min(
      (index / (totalItems - visibleItems)) * maxThumbLeft,
      maxThumbLeft
    );
    thumbRef.current.style.left = `${thumbLeft}%`;
  };

  useEffect(() => {
    updateThumbPosition();
  }, [currentCardIndex]);

  const filteredData = data?.filter(item => {
    // Check if the salary is null or greater than or equal to 5000
    return item.salary === null || item.salary >= 5000;
  });

  return (
    <div
      className="internships-jobs-section"
      style={{ background: '#F0FBFF', padding: '48px 0' }}
    >
      <div className="title">
      <h5 style={{ fontSize: '25px', textAlign: "center" }}>Top Jobs and Internships</h5>
        {/* <p>
          as per your <a href="/user_preference/preferences">preferences</a>
        </p> */}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
          <div className="scroll" style={{maxWidth: "85vw"}}>
            <Slider ref={slider} {...settings}>
              {filteredData.map((item, index) => (
                <Card props={item} key={index} />
              ))}
            </Slider>
          </div>

        <div
          className="scroll-buttons"
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '32px',
          }}
        >
          <button
            onClick={handlePrevButtonClick}
            disabled={currentCardIndex === 0}
            ref={sliderRef}
            className="arrow"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-left"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"
              />
            </svg>
          </button>

          <div className="track">
            <div
              className="thumb"
              ref={thumbRef}
              style={{ width: '37.046%' }}
            ></div>
          </div>

          <button
            onClick={handleNextButtonClick}
            // disabled={currentCardIndex >= data.length - 4}
            disabled={currentCardIndex >= data.length - settings.slidesToScroll}
            ref={sliderRef}
            className="arrow"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-chevron-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
