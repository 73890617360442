import React, { useState, useEffect } from "react";
import "./css/StudentSignup.scss";
import GoogleIcon from "../../assets/images/icons/google.png";
import axios from "axios";
import Spinner from "../Layout/Spinner";
import SignupImage1 from "../../assets/images/signupEmployee1.png";
import SignupImage2 from "../../assets/images/signupEmployee2.png";
import Recruiter from "../../assets/images/recruiter.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import OAuth2Login from "react-simple-oauth2-login";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function StudentSignup() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from || '/';
  const [user, setUser] = useState({
     role: "User",
     availability: "",
    });
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const dispatch = useDispatch();


  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let isValid = true;

    if (!values.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!values.email) {
      errors.email = "Email is required";
      isValid = false;
    }  

   else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format";
      isValid = false;
    }

    if (!values.password) {
      errors.password = "password is required";
      isValid = false;
    }

    else if (values.password.length < 6) {
      errors.password = "Password must be at least six characters long";
      isValid = false;
    }

    if (!values.availability) {
      errors.availability = "availability is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const handleUserChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

 

  const handleChange = (event) => {
    setUser({ ...user,  availability: event.target.value});
  };

  const signup = async (event) => {
    event.preventDefault();
    setLoading(true);
    const isValid = validate(user);
    if (isValid) {
      try {
        const { data } = await axios.post("/api/user/otp", user);
        console.log(data);
        if (data.status === 404) {
          setLoading(false);
          toast.error("This email is already used by another user", {
            style: {
              padding: '18px',
            }
          });
        }
        else {
          setLoading(false);
        }

        // navigate('/otpverification', { state: user })
        navigate('/otpverification', { state: { ...user, from } });

      } catch (error) {
        console.log(error.response.data);
        setLoading(false);
        toast.error(error.response.data, {
          style: {
            padding: '18px',
          }
        });

      }



    } else {
      setLoading(false);
      const error = Object.values(formErrors);
      toast.error(error[0] ? error[0] : "All fields are required", {
        style: {
          padding: '18px',
        }
      });

    }

  };
  useEffect(() => {
    if (localStorage.getItem('token')) {
      const userType = localStorage.getItem('userType');

      if (userType === 'User' || userType === 'Employer') {
        navigate("/");
      }
    }
  }, []);


  const handleSuccess = (token) => {
    console.log("Login successful:", token);
  };

  const handleFailure = (error) => {
   
    console.error("Login failed:", error);
  };


  const handleGoogleLoginSuccess = async (credentialResponse) => {
    try {
      const decoded = jwtDecode(credentialResponse.credential);

      console.log("Decoded token:", decoded);

      const response = await axios.post("/api/user/googleLogin", {
        tokenId: credentialResponse.credential,
      });

      console.log("Server response:", response.data);

      const { token, user } = response.data;

      if (token) {
        localStorage.setItem('token', token);

        if (user.role === 'User') {
          try {
            const data = await fetch("/api/user/me", {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                "token": localStorage.getItem('token')
              }
            });
            const json = await data.json();
            const logData = json.user;
            dispatch({ type: "LOAD_USER_SUCCESS", payload: logData });
            console.log("Loaded user data:", logData);
          } catch (error) {
            console.error("Error loading user data:", error);
          }

          localStorage.setItem('userType', 'User');

        } 
        // else if (user.role === 'Employer') {
        //   localStorage.setItem('userType', 'Employer');
        // }
        navigate("/");
      } else {
        console.log("Login failed:", response.data.message);
      }
    } catch (error) {
      toast.error(error.response ? error.response.data.message : error.message);

      console.error("Error during Google login:", error);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login failed:", error);
  };



  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        const myButton = document.querySelector('.btnsign');
        if (myButton) {
          myButton.click();
        } else {
          console.error('Button not found');
        }
      }
    };
    // Attach the event listener to the document
    document.addEventListener('keydown', handleKeyPress);
    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <>

      <div className="signup-container container-fluid py-4">
        <div className="studentsignup my-3 container" >
          <div className="row mx-auto">
            <div className="hideMob col-lg-7 col-md-7 col-sm-12 mx-auto mb-5 ">
              <div style={{ justifyContent: "center" }}>
                <h3 className="title mx-3">
                  Connecting Talent to Opportunities: Your One-Stop Destination
                  for Internships and Jobs
                </h3>
                <p className="para12345 mx-3">
                  Looking for the perfect internship or job? Look no further than
                  our comprehensive portal, where you'll find a wide variety of
                  opportunities from top companies and organizations. Our
                  user-friendly platform makes it easy to search, apply, and track
                  your progress, so you can focus on what matters most: advancing
                  your career.
                </p>
               {/* <div className="row my-5">
                  <div className="col-lg-1 col-md-1 col-sm-1 mb-3">
                    <ul
                      className="list-unstyled  ml-3"
                      style={{ display: "inline-flex" }}
                    >
                      <li className="mx-a1">
                        <img className="avtar" src={SignupImage1} alt="signup" />
                      </li>
                      <li className="mx-auto">
                        <img className="avtar" src={SignupImage2} alt="signup" />
                      </li>
                      <li className="mx-auto ">
                        <img className="avtar" src={SignupImage1} alt="signup" />
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-11 col-md-11 col-sm-11 mb-3 pt-1">
                    <p className="parahike mt-2 mx-2">
                      3k+ internship seekers joined us, now it’s your turn
                    </p>
                  </div>
                </div>*/}
                <div className="text-center imagesection">
                  <img src={Recruiter} alt="recruiter" />
                </div>
              </div>
            </div>



            <div className="col-lg-5 col-md-5 col-sm-12 my-5 mx-auto studentform">
              <form className="form-box">
                <div className="form">
                  <h2 className="title text-center">Sign Up</h2>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={user.name}
                    onChange={handleUserChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="fa fa-user" style={{ fontSize: "20px" }}></i>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={user.email}
                    onChange={handleUserChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="fa fa-envelope" style={{ fontSize: "17px" }}></i>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={user.password}
                    onChange={handleUserChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i className="fa fa-lock" style={{ fontSize: "22px" }}></i>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{height: "13px"}}></div>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Availability</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={user.availability}
                        label="Availability"
                        onChange={handleChange}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              fontSize: '15px' // Change the font size to your desired value
                            },
                          },
                        }}
                      >
                         <MenuItem value={"Immediate"}>Immediate</MenuItem>
                        <MenuItem value={"Within 1 Week"}>Within 1 Week</MenuItem>
                        <MenuItem value={"Within 2 Weeks"}>Within 2 Weeks</MenuItem>
                        <MenuItem value={"Within 3 Weeks"}>Within 3 Weeks</MenuItem>
                        <MenuItem value={"Within 4 Weeks"}>Within 4 Weeks</MenuItem>
                        <MenuItem value={"Within 5 Weeks"}>Within 5 Weeks</MenuItem>
                        <MenuItem value={"Within 6 Weeks"}>Within 6 Weeks</MenuItem>
                        <MenuItem value={"Within 7 Weeks"}>Within 7 Weeks</MenuItem>
                        <MenuItem value={"Within 8 Weeks"}>Within 8 Weeks</MenuItem>
                      </Select>
                    </FormControl>
              
                  {/* <input type="checkbox"></input><span><p className="label">
                  By Signing up, you agree to{" "}
                  <a href="/">Terms & Conditions.</a>
                </p></span> */}
                  <button className="btn btnsign" style={{ width: "100%", marginTop: "15px", paddingTop:'16px', paddingBottom:'16px' }} onClick={signup}>
                    {loading ? (
                      <Spinner />
                    ) : (
                      <span style={{ fontSize: "1.6rem" ,fontWeight:'800'}}>Sign Up</span>
                    )}
                  </button>

                  <p className="text-center pt-4" style={{ fontSize: "13px" ,marginTop:'12px'}}>
                    Already have an Account?{" "}
                    <a href="/login" className="font-weight-bold" style={{ fontSize: "13px",marginLeft:'0px' }}>
                      Log In
                    </a>
                  </p>
                  {/* <div className="row my-2">
                    <div className="col-5 my-4">
                      <hr style={{height: "0.1px"}}></hr>
                    </div>
                    <div className="col-2 my-4 text-center">
                      <p style={{ fontWeight: "500", color: "#7A7474" }}>or</p>
                    </div>
                    <div className="col-5 my-4">
                      <hr style={{height: "0.1px"}}></hr>
                    </div>
                  </div> */}
                  {/* <div className="btn-google">
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>




<GoogleOAuthProvider clientId="100664819392-m58df630lnjhu1u5lnl8sgn72n330u0u.apps.googleusercontent.com">
  <GoogleLogin
    onSuccess={handleGoogleLoginSuccess}
    onError={handleGoogleLoginFailure}
  />
</GoogleOAuthProvider>

</div></div> */}

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
      position="top-center"
      autoClose={10000}
      />
    </>
  );
}

export default StudentSignup;
