import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

<ToastContainer
position="top-center"
autoClose={10000}
/>
export const loadUser = () => async (dispatch) => {
    try {
        dispatch({ type: "LOAD_USER_REQUEST" });


        const data = await fetch("/api/user/me", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "token": localStorage.getItem('token')
              
            }
        });

        const json = await data.json();
        const logData = json.user
        if (logData && logData.active === false) {
            localStorage.removeItem('token');
            localStorage.removeItem('userType');
            localStorage.removeItem('id');
            window.location.href = '/login'; 
        }
      // console.log(logData)
        dispatch({ type: "LOAD_USER_SUCCESS", payload: logData });
    } catch (error) {
        dispatch({ type: "LOAD_USER_FAIL" });
    }
}



export const loginUser = (user, loginType) => async (dispatch) => {
    try {
        dispatch({ type: "LOGIN_REQUEST" });
        const { data } = await axios.post("/api/user/login", {
            ...user, loginType
        });
        localStorage.setItem('token',data.token);
        dispatch({ type: "LOGIN_SUCCESS" });
        try {
            dispatch({ type: "LOAD_USER_REQUEST" });
            const data = await fetch("/api/user/me", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "token": localStorage.getItem('token')   
                }});
            const json = await data.json();
            const logData = json.user;
            console.log(logData,"logData")
            dispatch({ type: "LOAD_USER_SUCCESS", payload: logData });
        } catch (error) {
            dispatch({ type: "LOAD_USER_FAIL" });
        }
        toast.success("Login Successfully");
    } catch (error) {
        
        toast.error(error.response.data);
        dispatch({ type: "LOGIN_FAIL" });
    }
}

export const loginUserForAdminCandidate = (userId, loginType) => async (dispatch) => {
    try {
        dispatch({ type: "LOGIN_REQUEST" });
        const { data } = await axios.post("/api/user/getccesstokenadmincandidate", {
            userId, loginType
        });
        console.log(data,"success")
        localStorage.setItem('token',data.token);
        dispatch({ type: "LOGIN_SUCCESS" });
        try {
            dispatch({ type: "LOAD_USER_REQUEST" });
            const data = await fetch("/api/user/me", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "token": localStorage.getItem('token')   
                }});
            const json = await data.json();
            const logData = json.user;
            console.log(logData,"logData")
            dispatch({ type: "LOAD_USER_SUCCESS", payload: logData });
        } catch (error) {
            dispatch({ type: "LOAD_USER_FAIL" });
        }
        toast.success("Login Successfully");
    } catch (error) {
        
        toast.error(error.response.data);
        dispatch({ type: "LOGIN_FAIL" });
    }
}



export const logoutUser = () => async (dispatch) => {
    try {
        dispatch({ type: "LOGOUT_REQUEST" });
        const userType = localStorage.getItem('userType');
        localStorage.clear();
        toast.success("Logout Successfully");
        dispatch({ type: "LOGOUT_SUCCESS" });

        if (userType === 'Admin') {
            window.location.href = '/admin_login';
          } else {
            window.location.href = '/login';
          }
        // Broadcast logout message to other tabs
        const broadcastChannel = new BroadcastChannel("logout_channel");
        broadcastChannel.postMessage("logout");
    } catch (error) {
        dispatch({ type: "LOGOUT_FAIL" });
    }
}



export const loginEmployer = (user, loginType) => async (dispatch) => {
    try {
        dispatch({ type: "LOGIN_REQUEST" });
        const { data } = await axios.post("/api/employer/login", {
            ...user, loginType
        });
        localStorage.setItem('token',data.token);
        dispatch({ type: "LOGIN_SUCCESS" });
        try {
            dispatch({ type: "LOAD_USER_REQUEST" });
            const data = await fetch("/api/user/me", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "token": localStorage.getItem('token')   
                }});
            const json = await data.json();
            const logData = json.user;
        
            dispatch({ type: "LOAD_USER_SUCCESS", payload: logData });
        } catch (error) {
            dispatch({ type: "LOAD_USER_FAIL" });
        }
        toast.success("Login Successfully");
    } catch (error) {
        
        toast.error(error.response.data);
        dispatch({ type: "LOGIN_FAIL" });
    }
}

export const loginUserForAdminEmployee = (userId, loginType) => async (dispatch) => {
    try {
        dispatch({ type: "LOGIN_REQUEST" });
        const { data } = await axios.post("/api/employer/getaccesstokenadminemployee", {
            userId, loginType
        });
        localStorage.setItem('token',data.token);
        dispatch({ type: "LOGIN_SUCCESS" });
        try {
            dispatch({ type: "LOAD_USER_REQUEST" });
            const data = await fetch("/api/user/me", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "token": localStorage.getItem('token')   
                }});
            const json = await data.json();
            const logData = json.user;
        
            dispatch({ type: "LOAD_USER_SUCCESS", payload: logData });
        } catch (error) {
            dispatch({ type: "LOAD_USER_FAIL" });
        }
        toast.success("Login Successfully");
    } catch (error) {
        
        toast.error(error.response.data);
        dispatch({ type: "LOGIN_FAIL" });
    }
}

export const loginAdmin = (admin, loginType) => async (dispatch) => {
    try {
      dispatch({ type: "LOGIN_REQUEST" });
  
      const { data } = await axios.post("/api/admin/login", {
        ...admin,
        loginType
      });
  
      localStorage.setItem('token', data.token);
      dispatch({ type: "LOGIN_SUCCESS" });
  
      try {
        dispatch({ type: "LOAD_USER_REQUEST" });
  
        const userData = await fetch("/api/user/me", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            "token": localStorage.getItem('token')
          }
        });

        const json = await userData.json();
        const logData = json.user;
  
        dispatch({ type: "LOAD_USER_SUCCESS", payload: logData });
      } catch (error) {
        dispatch({ type: "LOAD_USER_FAIL" });
      }
  
      toast.success("Admin Login Successfully");
    } catch (error) {
      toast.error(error.response.data);
      dispatch({ type: "LOGIN_FAIL" });
    }
  };