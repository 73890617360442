import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const EmployerConnectInfo = () => {
    const { user } = useSelector((state) => state.user);
    const [connectCredit, setConnectCredit] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        const getConnectCreditInfo = async () => {
            try {
                const response = await axios.get(`/api/employer/getConnectCreditInfomrationofEmployer/${user?._id}`);
                setConnectCredit(response.data.connectCredit);
            } catch (error) {
                console.error('Error fetching connectCredit:', error);
            }
        };

        if (user && user.role === 'Employer') {
            getConnectCreditInfo();
        }
    }, [user]);
    const handleBuyConnects = () => {
        navigate('/employerbuyconnects');
    };
    const handleBack=()=>{
        // navigate('/employerApplication')
        navigate('/employerApplication/Internship')
    }
    return (
        <>
            <div className='myapplicationsbuymain'>
                <div className='my-applicationsbuyconnects2'>
                <div className='my-applicationsbuyconnectsheader' style={{ height: '50px' ,fontWeight:'700', padding:'17px'}}>Connects Summary</div>
                    <div className='my-applicationsbuyconnectsbodyinformation'>
                        <div style={{display:'flex', gap:'5px'}}>
                            <div style={{ fontSize: '16px', fontWeight: '500', marginBottom: '11px' }}>Your Available Connects :</div>
                            <div><b>{connectCredit}</b></div>
                        </div>
                        <div><b>1</b> Job post requires <b>8</b> connects</div>
                        <div><b>1</b> Internship post requires <b>4</b> connects</div>
                        {/* <div><b>1</b> contact view requires <b>1</b> connect</div> */}
                        <div>Viewing job seekers' contacts (email, phone, direct chat) on the "Job Seekers" page, requires <b>1</b> connect</div>

                        <div style={{display:'flex',alignItems:'flex-end',alignSelf:'end', gap:'10px'}}>
                        <button className='employerbuttonbacktodash' onClick={handleBack}>Back</button>
                        <button className="employerbuttontobuyinformation" onClick={handleBuyConnects}>Buy Connects</button>
            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmployerConnectInfo