import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart  } from '@fortawesome/free-regular-svg-icons';
import { FaBookmark  } from "react-icons/fa";
import list from "../../assets/images/list.png";
import { useNavigate } from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";
import { CgFileDocument } from 'react-icons/cg'

const StudentSavedjob = () => {

  const openPreviewPageInNewTab = (data) => {
    window.open(`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase());
  };

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);
  const [matchedJobs, setMatchedJobs] = useState([]);
  const [jobData, setJobData] = useState("")
    const [count, setCount] = useState("")
    const [savedJobs, setSavedJobs] = useState("")
    const [page, setPage] = useState(1)
    const [forceRender, setForceRender] = useState(false);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();


  const getSavedJobs = async () => {
    setLoading(true);
    try {
      const { data } = await axios.get("/api/user/getSavedJobs", {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      setSavedJobs(data.savedJobs);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getUserData = async (page) => {
    try {
      const { data } = await axios.post("/api/employer/getAllJobs", { page }, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      setCount(data.count);
      setJobData(data.job);
      // Find and store matched jobs based on saved job IDs
      const matchedJobsData = data.job.filter(job => savedJobs.includes(job._id));
      setMatchedJobs(matchedJobsData);
      
    } catch (error) {
      console.log(error.response.data);
    }
  };

  useEffect(() => {
    getSavedJobs();
    getUserData(page);
  }, [page,forceRender]); 


  const handleRemoveJob = async (jobId) => {
    console.log(`Attempting to remove job with ID: ${jobId}`);
    try {
      const { data } = await axios.get(`/api/user/removeJob/${jobId}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
  
      console.log('Response from removeJob API:', data);
  
      if (data.success) {
        console.log('Job removed successfully');
        setMatchedJobs(prevJobs => prevJobs.filter(job => job._id !== jobId));
        setForceRender(prev => !prev);

      } else {
        console.log('Failed to remove job');
      }
    } catch (error) {
      console.error('Error in handleRemoveJob:', error);
    }
  };
  
  
  return (
    <div>
     
    <div className="my-applications" style={{ background: 'white' }}>
    {loading ? <>
              
              <div className="my-auto"  style={{ textAlign: 'center' }}>
                      <p style={{ color: "#4B92C8", fontWeight: "500", marginTop: '-15px', fontSize: "20px", marginLeft: '43px', marginBottom: "5px" }}>Loading..</p>
                      <PropagateLoader
                          color={'#4B92C8'}
                          loading={true}
                          size={15}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          style={{marginLeft: "27px"}}
                      />
                  </div>
    

        </> : <>
    {savedJobs && savedJobs.length>0?
    <>
      <h2 className="title">Saved Jobs</h2>
      <div className="table-box table-responsive border" >
            <table className="applications table">
          <thead>
            <tr >
              <th style={{paddingLeft: "30px"}}>COMPANY</th>
              <th style={{paddingLeft: "30px"}}>PROFILE</th>
              <th style={{paddingLeft: "80px"}}>NUMBER OF <br/>APPLICANTS</th>
              <th style={{paddingLeft: "80px"}}>VIEW JOB</th>
              <th style={{paddingLeft: "80px"}}>REMOVE JOB</th>
            </tr>
          </thead>
          <tbody>
       {Array.isArray(savedJobs) && savedJobs.map((job, index) => (
  <tr key={index}>
    <td style={{fontSize: "15px", textAlign: 'start'}}>{job.organisationName}</td>
    
    <td style={{fontSize: "15px", textAlign: 'start'}}>{job.profile}</td>
    <td style={{fontSize: "15px", textAlign: "center"}}>{job.userApplied.length}</td>


  
     <td style={{ textAlign: "center"}} > 
      <CgFileDocument className="docIcon"  onClick={() => openPreviewPageInNewTab(job)} style={{cursor: 'pointer'}} />
      </td>
      <td  style={{ textAlign: "center"}}>
   <FaBookmark
  style={{
    marginLeft:'-20px',
    fontSize: '18px',
    color: '#0991DA', 
    cursor: 'pointer'
  }}
  onClick={() => handleRemoveJob(job._id)} 
/>
    </td>           
  </tr>
))}
          </tbody>
        </table>
      </div>
      </>
       :
       <>
         <div className="text-center my-auto" >
           <i class="fa fa-window-close-o mx-auto" aria-hidden="true" style={{ fontSize: '200px' }}></i>
           <h1>Job not Found</h1>
         </div>
       </>
}
</>
}
        </div>
       
  </div>
  );
};

export default StudentSavedjob;
