import React, { useState, useEffect, useRef } from "react";
import "./css/employerApplication.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropagateLoader from "react-spinners/PropagateLoader";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaCrown } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FaRegPenToSquare } from "react-icons/fa6";
import { MdContentCopy } from "react-icons/md";
import { CiViewList } from "react-icons/ci";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Dropdown } from "react-bootstrap";
import Alert from "@mui/material/Alert";
import { IoEyeOutline } from "react-icons/io5";
import { Tooltip } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { loadUser } from "../../actions/userActions";
import Popup from "reactjs-popup";
import { RxCross1 } from "react-icons/rx";

const limit = 15;
function EmployerApplications() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0);
  }, []);


  const handleLinkedInShare = (data) => {
    // Construct the LinkedIn share URL
    const linkedinShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=https://hirekingdom.com/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.employer.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase();
  
    // Open LinkedIn share URL in a new tab
    window.open(linkedinShareUrl, '_blank');
  };
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [jobData, setJobData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [forceRender, setForceRender] = useState(false);
  // const [toggleButton, setToggleButton] = useState(true);
  const [toggleButton, setToggleButton] = useState(location.pathname.includes('Internship'));
  const [showAlert, setShowAlert] = useState(false);
  const [newDate, setNewDate] = useState(false);
  const [selectedJob, setSelectedJob] = useState("");
  // useEffect(() => {
  //   dispatch(loadUser());
  // }, []);
  const getUserData = async (page) => {
    setLoading(true);

    try {
      const { data } = await axios.post(
        "/api/employer/getAllJobs",
        { page, opportunityType: toggleButton ? "Internship" : "Job" },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );
      const jobD = data.jobs;
      setCount(data.count);
      setJobData(jobD);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data);
      setLoading(false);
    }
  };

  const forw = () => {
    setPage((prev) => prev + 1);
    window.scrollTo(0, 0);
  };

  const backw = () => {
    setPage((prev) => prev - 1);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    getUserData(page);
  }, []);

  useEffect(() => {
    getUserData(1);
  }, [toggleButton]);

  const handleCloseJob = async (jobId) => {
    const status = "Close";
    try {
      const response = await axios.post(
        `/api/employer/closeJob/${jobId}`,
        { status },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );

      if (response.data.success) {
        toast.success("Job closed successfully");
        setForceRender((prev) => !prev);
      } else {
        console.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setShowAlert(false);
    }
  };

  useEffect(() => {
    getUserData(page);
  }, [page, forceRender]);

  const handleToggleButton = (state) => {
    setToggleButton(state);
    setPage(1);

    const newType = state ? "Internship" : "Job";
    navigate(`/employerApplication/${newType}`);
  };

  function getDate(date) {
    if (!date) date = new Date();
    else {
      date = new Date(date);
    }

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const handleSetNewDeadline = async () => {
    try {
      const response = await axios.post(
        "/api/employer/setNewDeadline",
        { deadline: newDate, id: selectedJob },
        {
          headers: {
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      );

      if (response.status == 200) {
        alert("Deadline extended successfully");
      }
    } catch (error) {
      console.log(error);
    } finally {
      getUserData(page);
      setShowAlert(false);
    }
  };

  const handleExtendDeadline = async (value, id) => {
    const date = new Date(value);
    setNewDate(date);
    setSelectedJob(id);
    setShowAlert(true);
    window.scrollTo(0, 0);
  };
  jobData && jobData?.sort((a, b) => new Date(b.datePosted) - new Date(a.datePosted));
  console.log(jobData,"after sort")

  const [showModal, setShowModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const handleDeleteConfirmation = (id) => {
    setIdToDelete(id);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    await handleCloseJob(idToDelete);
    setShowModal(false);
  };

  const handleCancelDelete = () => {
    setShowModal(false);
  };
  return (
    <>
      <div id="my-applications">
        {showAlert && (
          <div
            className="w-50 mx-auto alert alert-primary alert-dismissible fade show d-flex flex-column"
            role="alert"
            style={{ background: "white" }}
          >
            Are you sure you want to extend the internship deadline? Please note that you can extend the deadline only once and the change is
            irreversible.
            <div className="d-flex align-items-center justify-content-center gap-5 mt-2">
              <button onClick={() => handleSetNewDeadline()} type="button" style={{ border: "none", background: "transparent" }}>
                Ok
              </button>
              <button onClick={() => setShowAlert(false)} type="button" style={{ border: "none", background: "transparent" }}>
                Cancel
              </button>
            </div>
          </div>
        )}
        {loading ? (
          <>
            <div className="my-auto" style={{ textAlign: "center" }}>
              <p style={{ color: "#4B92C8", fontWeight: "500", fontSize: "20px", marginLeft: "43px", marginBottom: "5px" }}>Loading..</p>
              <PropagateLoader
                color={"#4B92C8"}
                loading={true}
                size={15}
                aria-label="Loading Spinner"
                data-testid="loader"
                style={{ marginLeft: "27px" }}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-center">
              {/* <button
                onClick={() => handleToggleButton(true)}
                className={`toggle-btn px-4 py-2 bg-transparent border-2 border-bottom ${toggleButton ? "border-primary text-primary" : "text-dark"}`}
              >
                Internships
              </button>
              <button
                onClick={() => handleToggleButton(false)}
                className={`toggle-btn px-4 py-2 bg-transparent border-2 border-bottom ${
                  !toggleButton ? "border-primary text-primary" : "text-dark"
                }`}
              >
                Jobs
              </button> */}
                <Link
                  to="/employerApplication/Internship"
                  onClick={() => handleToggleButton(true)}
                  className={`toggle-btn px-4 py-2 bg-transparent border-2 border-bottom ${toggleButton ? "border-primary text-primary" : "text-dark"}`}
                  style={{textDecoration:'none'}}
                >
                  Internships
                </Link>
                <Link
                  to="/employerApplication/Job"
                  onClick={() => handleToggleButton(false)}
                  className={`toggle-btn px-4 py-2 bg-transparent border-2 border-bottom ${!toggleButton ? "border-primary text-primary" : "text-dark"
                    }`}
                  style={{textDecoration:'none'}}
                >
                  Jobs
                </Link>
            </div>

            {jobData.length > 0 ? (
              <>
                <div className="table-container">
                  <table>
                    <thead style={{ backgroundColor: "f8f8f8" }}>
                      <tr>
                        <th style={{ borderTopLeftRadius: "20px", paddingLeft: "35px" }}>{toggleButton ? "PROFILE" : "JOB TITLE"}</th>
                        <th className="text-center">STATUS</th>
                        <th>TOTAL VIEWs</th>
                        <th className="text-center ">ACTION</th>
                        <th className = "text-center">SHARE</th>
                       {/* <th style={{ border: "none", background: "white" }}></th> */}
                        <th style={{ borderTopRightRadius: "20px" }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobData?.map((data, index) => {
                        const fifteenDaysLater = new Date(data.deadline);
                        fifteenDaysLater.setDate(fifteenDaysLater.getDate() + 15);

                        // Check if current date is after 15 days from datePosted
                        const currentDate = new Date();
                        const disableButton = (currentDate > fifteenDaysLater) && (data.status === "Closed" || data.status === "Not Approved");
                        const encodedData = encodeURIComponent(JSON.stringify(data?._id));
                        return (
                          <>
                            <tr key={index}>
                              <td>
                                {data.profile} <br />{" "}
                                {!toggleButton && (
                                  <span style={{ fontSize: "12px" }}>
                                    (Exp: {data?.minExperience || 1}
                                    {data?.maxExperience ? `- ${data.maxExperience}` : ""} years)
                                  </span>
                                )}
                              </td>
                              <td>
                                {data.status == "Not Approved" ? (
                                  <button className="application-status active" style={{ backgroundColor: "#f8f8f8", color: "#484848" }}>
                                    Closed
                                  </button>
                                ) : (
                                  data.status == "Approved"? (
                                    <button className="application-status active">Active</button>
                                  ):(
                                    data.status == "Rejected" ? (
                                    <button className="application-status active" style={{ backgroundColor: "#FFEFEF", color: "#CF1D1D" }}>{data.status}</button>
                                    ):(
                                      <button className="application-status active" style={{ backgroundColor: "#FFFAE3", color: "#D8AF09" }}>{data.status}</button>
                                    )
                                  ) 
                                )}
                              </td>
                              <td>{data?.totalviews}</td>
                              {/* <td className="view">
                                <button
                                  className={`px-5 py-2 h4 text-white border-0 ${data.status == "Approved" ? "active" : "inactive"}`}
                                  onClick={() => navigate("/employerappreceived/All", { state: data })}
                                  style={{ minWidth: "80px", width: "100%" }}
                                >
                                  View applications ({data?.userApplied?.length})
                                </button>
                              </td> */}
                              <td className="view">
                                {disableButton ? (
                                  <button
                                    className={`px-5 py-2 h4 text-white border-0 inactive`}
                                    style={{ minWidth: "80px", width: "100%", cursor: 'not-allowed' }}
                                    disabled
                                  >
                                    View applications ({data?.userApplied?.length})
                                  </button>
                                ) : (
                                  <>
                                  {/* <button
                                    className={`px-5 py-2 h4 text-white border-0 ${data.status === "Approved" ? "active" : "inactive"}`}
                                    onClick={() => navigate("/employerappreceived/All", { state: data })}
                                    style={{ minWidth: "80px", width: "100%" }}
                                  >
                                    View applications ({data?.userApplied?.length})
                                  </button> */}
                                  <a
                                        href={`/employerappreceived/All?data=${encodedData}`}
                                        className={`px-5 py-2 h4 text-white border-0 ${data.status === "Approved" ? "active" : "inactive"}`}
                                        style={{ minWidth: "80px", width: "100%", display: "inline-block", textAlign: "center", textDecoration:'none' }}
                                      >
                                        View applications ({data?.userApplied?.length})
                                      </a>
                                  </>
                                )}
                              </td>
                              <td style={{ width: "100px" }}>
                                {/* <div className="d-flex justify-content-between align-items-center">
                                 <span className="premium">
                                    <FaCrown />
                                    <span>Premium</span>
                                  </span>*/}
                                  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    onClick={()=>handleLinkedInShare(data)}
                                    style={{cursor: "pointer"}}
                                    >
                                    <path
                                      d="M26.0002 25.5V18.9075C26.0002 15.6675 25.3027 13.1925 21.5227 13.1925C19.7002 13.1925 18.4852 14.1825 17.9902 15.1275H17.9452V13.485H14.3677V25.5H18.1027V19.5375C18.1027 17.9625 18.3952 16.455 20.3302 16.455C22.2427 16.455 22.2652 18.2325 22.2652 19.6275V25.4775H26.0002V25.5Z"
                                      fill="#2867B2"
                                    />
                                    <path d="M8.29248 13.485H12.0275V25.5H8.29248V13.485Z" fill="#2867B2" />
                                    <path
                                      d="M10.16 7.5C8.9675 7.5 8 8.4675 8 9.66C8 10.8525 8.9675 11.8425 10.16 11.8425C11.3525 11.8425 12.32 10.8525 12.32 9.66C12.32 8.4675 11.3525 7.5 10.16 7.5Z"
                                      fill="#2867B2"
                                    />
                                    <rect x="1" y="1" width="32" height="32" rx="6" stroke="#2867B2" />
                                  </svg>
                              {/*</div>*/}
                              </td>
                              <td>
                                <DropdownHere
                                  data={data}
                                  index={index}
                                  navigate={navigate}
                                  toggleButton={toggleButton}
                                  handleExtendDeadline={handleExtendDeadline}
                                  getDate={getDate}
                                  handleCloseJob={handleCloseJob}
                                />
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className="table-container-mobile">
                  {jobData?.map((data, index) => {
                    const fifteenDaysLater = new Date(data.deadline);
                    fifteenDaysLater.setDate(fifteenDaysLater.getDate() + 15);

                    // Check if current date is after 15 days from datePosted
                    const currentDate = new Date();
                    const disableButton = (currentDate > fifteenDaysLater) && (data.status === "Closed" || data.status === "Not Approved");
                    const encodedData = encodeURIComponent(JSON.stringify(data?._id));
                    return (
                    <div className="job-container">
                      <div className="d-flex align-items-center">
                        <div className="profile">
                          <span>{data?.profile}</span>
                          {!toggleButton && (
                            <span>
                              <>
                                (Exp: {data?.minExperience || 1}
                                {data?.maxExperience ? `- ${data.maxExperience}` : ""} years)
                              </>
                            </span>
                          )}
                        </div>
                        {/*<span className="premium-mobile">
                          <FaCrown />
                          <span>Premium</span>
                        </span>*/}
                        <span className="share-link" onClick={()=>handleLinkedInShare(data)}
                        style={{cursor: "pointer"}}>
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                          
                          >
                            <g clip-path="url(#clip0_22339_11344)">
                              <path
                                d="M10.8332 2.5H8.33317C4.65127 2.5 1.6665 5.48477 1.6665 9.16667V11.6667C1.6665 15.3486 4.65127 18.3333 8.33317 18.3333H10.8332C14.5151 18.3333 17.4998 15.3486 17.4998 11.6667V9.16667C17.4998 5.48477 14.5151 2.5 10.8332 2.5Z"
                                fill="white"
                              />
                              <path
                                d="M10 0C8.02219 0 6.08879 0.586491 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.80429 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM7.09417 15.1175H4.65834V7.79H7.09417V15.1175ZM5.87667 6.78917H5.86084C5.68684 6.79943 5.5126 6.77374 5.34896 6.7137C5.18533 6.65366 5.03582 6.56057 4.90975 6.44021C4.78367 6.31985 4.68374 6.17482 4.61618 6.01415C4.54862 5.85348 4.51488 5.68061 4.51706 5.50633C4.51924 5.33204 4.5573 5.16008 4.62885 5.00114C4.70041 4.84221 4.80394 4.69972 4.93298 4.58256C5.06203 4.46539 5.21382 4.37606 5.3789 4.32014C5.54399 4.26421 5.71882 4.24289 5.8925 4.2575C6.06743 4.24444 6.24317 4.26776 6.40864 4.32598C6.57411 4.38421 6.72572 4.47609 6.85392 4.59582C6.98212 4.71555 7.08413 4.86054 7.15351 5.02165C7.22289 5.18277 7.25814 5.35651 7.25705 5.53192C7.25595 5.70733 7.21853 5.88061 7.14713 6.04084C7.07574 6.20107 6.97193 6.34478 6.84225 6.4629C6.71256 6.58102 6.55981 6.67099 6.39362 6.72714C6.22744 6.7833 6.05142 6.80441 5.87667 6.78917ZM15.8767 15.1167H13.4408V11.2C13.4408 10.215 13.0883 9.54333 12.2075 9.54333C11.9328 9.54493 11.6652 9.63138 11.4415 9.79083C11.2177 9.95029 11.0487 10.175 10.9575 10.4342C10.894 10.6256 10.8658 10.8269 10.8742 11.0283V15.12H8.44167C8.44167 15.12 8.47334 8.48 8.44167 7.79333H10.8767V8.83083C11.0971 8.44789 11.4182 8.13269 11.8052 7.91951C12.1922 7.70632 12.6302 7.60331 13.0717 7.62167C14.6742 7.62167 15.8758 8.66917 15.8758 10.9192L15.8767 15.1167Z"
                                fill="#2867B2"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_22339_11344">
                                <rect width="20" height="20" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <span>Share</span>
                        </span>
                      </div>

                      <div className="d-flex align-items-center gap-4">
                      {data.status == "Not Approved" ? (
                                  <button className="application-status active" style={{ backgroundColor: "#f8f8f8", color: "#484848" }}>
                                    Closed
                                  </button>
                                ) : (
                                  data.status == "Approved"? (
                                    <button className="application-status active">Active</button>
                                  ):(
                                    data.status == "Rejected" ? (
                                    <button className="application-status active" style={{ backgroundColor: "#FFEFEF", color: "#CF1D1D" }}>{data.status}</button>
                                    ):(
                                      <button className="application-status active" style={{ backgroundColor: "#FFFAE3", color: "#D8AF09" }}>{data.status}</button>
                                    )
                                  ) 
                                )}

                        <div
                          className="d-inline-flex align-items-center justify-content-center gap-2"
                          style={{ border: "1px solid #ddd", padding: "2px 12px", borderRadius: "40px", fontSize: "14px" }}
                        >
                          <IoEyeOutline />
                          {data?.totalviews || 4657} views
                        </div>
                      </div>

                      <TooltipComponent data={data} index={index} getDate={getDate} handleExtendDeadline={handleExtendDeadline} />
                      <div style={{ width: "100%", backgroundColor: "#f8f8f8", height: "1px" }}></div>

                      <div className="container-footer">
                        <div className="d-inline-flex align-items-center">
                          {data?.status == "Approved" && (
                            <>
                            {/* <a href="#" onClick={() => handleCloseJob(data._id)}> */}
                            <a href="#" onClick={() => handleDeleteConfirmation(data._id)}>
                              <IoCloseCircleOutline /> Close
                            </a>
                            </>
                          )}
                          <a href={`newpost/${data?._id}`}>
                            <MdContentCopy /> Post similar {toggleButton ? "internship" : "job"}
                          </a>
                          <a href={`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase()}>
                            <CiViewList /> View {toggleButton ? "internship" : "job"}
                          </a>
                        </div>
                        <div className="view">
                          {/* <button
                            className={`px-5 py-3 h4 text-white border-0 ${data.status == "Approved" ? "active" : "inactive"}`}
                            onClick={() => navigate("/employerappreceived/All", { state: data })}
                            style={{ minWidth: "80px", width: "100%" }}
                          >
                            View applications ({data?.userApplied?.length})
                          </button> */}
                          {disableButton ? (
                                  <button
                                    className={`px-5 py-2 h4 text-white border-0 inactive`}
                                    style={{ minWidth: "80px", width: "100%", cursor: 'not-allowed' }}
                                    disabled
                                  >
                                    View applications ({data?.userApplied?.length})
                                  </button>
                                ) : (
                                  <>
                                  {/* <button
                                    className={`px-5 py-2 h4 text-white border-0 ${data.status === "Approved" ? "active" : "inactive"}`}
                                    onClick={() => navigate("/employerappreceived/All", { state: data })}
                                    style={{ minWidth: "80px", width: "100%" }}
                                  >
                                    View applications ({data?.userApplied?.length})
                                  </button> */}
                                  <a
                                        href={`/employerappreceived/All?data=${encodedData}`}
                                        className={`px-5 py-3 h4 text-white border-0 ${data.status === "Approved" ? "active" : "inactive"}`}
                                        style={{ minWidth: "80px", width: "100%", display: "inline-block", textAlign: "center", textDecoration:'none' }}
                                      >
                                        View applications ({data?.userApplied?.length})
                                      </a>
                                  </>
                        )}
                        </div>
                      </div>
                    </div>
                    )
                  })}
                </div>
                <div className="pagination">
                  <div className="d-flex justify-content-start align-items-center flex-wrap">
                    <a
                      style={{ cursor: "pointer", fontSize: "20px", color: page != 1 ? "#008bdc" : "#484848" }}
                      onClick={() => {
                        page > 1 && backw();
                      }}
                    >{`<`}</a>
                    <span style={{ color: "#484848", fontSize: "14px" }}>
                      {page} / {Math.ceil(count / limit)}
                    </span>
                    <a
                      style={{ cursor: "pointer", fontSize: "20px", color: page < Math.ceil(count / limit) ? "#008bdc" : "#484848" }}
                      onClick={() => {
                        page < Math.ceil(count / limit) && forw();
                      }}
                    >{`>`}</a>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ fontSize: "14px", color: "#e5330e", textAlign: "center" }}>No applications posted</div>
            )}
          </>
        )}
      </div>

      <Popup open={showModal} modal closeOnDocumentClick={false}>
      <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
        <div className="popup-containernew">
          <div className="success-message mx-3" style={{marginBottom:'10px'}}>
            Are you sure you want to close?
          </div>
          <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', gap: '10px' ,marginBottom:'15px'}}>
            <button className='employerbuttonbacktodash' onClick={handleCancelDelete}>No</button>
            <button className="employerbuttontobuyinformation" style={{backgroundColor:'#ED5E68'}} onClick={handleConfirmDelete}>Yes</button>
          </div>

        </div>
      </div>
    </Popup>
    </>
  );
}

const DropdownHere = ({ data, index, navigate, toggleButton, handleExtendDeadline, getDate, handleCloseJob }) => {
  const [extend, setExtend] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const dropdownRef = useRef();

  const handleClickOutside = (event) => {
    const dropdownContainer = document.getElementById("dropdown-container");
    if (dropdownRef.current && !dropdownRef.current.contains(event.target) && dropdownContainer && !dropdownContainer.contains(event.target)) {
      setDropdown(false);
      setExtend(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClick = (value, event) => {
    handleExtendDeadline(value, data?._id);
  };
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const confirmCloseJob = () => {
    handleCloseJob(data._id);
    closeModal();
  };
  return (
    <>
      <div ref={dropdownRef} class="ms-auto" style={{ position: "relative" }}>
        <i
          class="fas fa-ellipsis-vertical"
          onClick={() => {
            setDropdown(!dropdown);
            setExtend(false);
          }}
          style={{ cursor: "pointer" }}
        >
          <BsThreeDotsVertical size={20} />
        </i>

        {dropdown && (
          <div id="dropdown-container">
             <span className="main-span">
            <a href={`/preview/${data.profile.replace(/[/?$%@&]/g, "-").replace(/ /g, "-")}-${data.opportunityType}-at-${data.organisationName.replace(/ /g, "-")}_${data._id}`.toLowerCase()}>
              <CiViewList />
              <span>View {toggleButton ? "Internship" : "Job"}</span>
            </a>
            </span>
            {data.status == "Approved" && (
              <>
              {/* <span className="main-span" onClick={() => handleCloseJob(data._id)}> */}
              <span className="main-span" onClick={openModal}>
                <IoCloseCircleOutline />
                <span>Close {toggleButton ? "Internship" : "Job"}</span>
              </span>
              </>
            )}
            {data.status == "Approved" && !data?.deadlineExtended && (
              <span className="main-span">
                <FaRegPenToSquare />
                <span onClick={() => setExtend(true)} className="d-flex flex-column align-items-start">
                  <span>Extend deadline</span>
                  <span style={{ fontSize: "13px", color: "#8a8a8a", marginTop: "-4px" }}>(Expires on {getDate(data?.deadline)})</span>
                </span>
              </span>
            )}
            <span className="main-span">
              <a href={`/newpost/${data._id}`} target="_blank">
                <MdContentCopy />
                <span>Post Similar {toggleButton ? "Internship" : "Job"}</span>
              </a>
            </span>
          </div>
        )}

        {extend && (
          <div id="calendar-container" style={{ position: "absolute", right: "0px", top: "30px", width: "250px", zIndex: "100" }}>
            <Calendar
              minDate={data?.deadline ? new Date(data?.deadline) : new Date()}
              defaultActiveStartDate={data?.deadline ? new Date(data?.deadline) : new Date()}
              onChange={handleClick}
            />
          </div>
        )}
      </div>

      <Popup open={isOpen} closeOnDocumentClick onClose={closeModal}>
        <div className="overlay" style={{ background: "rgba(0, 0, 0, 0.4)" }}>
        <div className="popup-containernew">
          <div className="success-message mx-3" style={{marginBottom:'10px'}}>
            Are you sure you want to close this {toggleButton ? "Internship" : "Job"}?
          </div>
          <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', gap: '10px' ,marginBottom:'15px'}}>
            <button className="employerbuttonbacktodash" onClick={closeModal}>No</button>
            <button className='employerbuttontobuyinformation' style={{backgroundColor:'#ED5E68'}} onClick={confirmCloseJob}>Yes</button>
          </div>
        </div>
      </div>
      </Popup>
    </>
  );
};

const TooltipComponent = ({ data, index, getDate, handleExtendDeadline }) => {
  const [open, setOpen] = useState(false);

  const closeRef = useRef();

  const handleClickOutside = (event) => {
    if (closeRef.current && !closeRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClick = (value, event) => {
    handleExtendDeadline(value, data?._id);
  };

  return (
    <>
      {!data?.deadlineExtended && data?.status == "Approved" && (
        <div ref={closeRef}>
          <Tooltip
            key={index}
            placement="bottom"
            open={open}
            content={
              <Calendar
                minDate={data?.deadline ? new Date(data?.deadline) : new Date()}
                defaultActiveStartDate={data?.deadline ? new Date(data?.deadline) : new Date()}
                onChange={handleClick}
              />
            }
          >
            <div style={{ fontSize: "12px", color: "#e5330e", fontWeight: "400" }}>
              Last date to recieve application is {getDate(data?.deadline)}.{" "}
              <span onClick={() => setOpen(!open)} style={{ color: "#008bdc", cursor: "pointer" }}>
                Extend deadline
              </span>
            </div>
          </Tooltip>
        </div>
      )}
        <ToastContainer
      position="top-center"
      autoClose={10000}
      />
    </>
  );
};
export default EmployerApplications;
