import A_plus_Pharma from '../../assets/images/companyLogo/A-plus_Pharma.png';
import Abira from '../../assets/images/companyLogo/Abira.png';
import AN_Insurance from '../../assets/images/companyLogo/AN_Insurance.png';
import BAP_Firm from '../../assets/images/companyLogo/BAP_Firm.png';
import Bhavik_Association from '../../assets/images/companyLogo/Bhavik_Association.png';
import Bixnov from '../../assets/images/companyLogo/Bixnov.png';
import Broklin from '../../assets/images/companyLogo/Broklin.png';
import BTOB_App from '../../assets/images/companyLogo/BTOB_App.png';
import BW_Story from '../../assets/images/companyLogo/BW_Story.png';
import Byul_Analytics from '../../assets/images/companyLogo/Byul_Analytics.png';
import Chia from '../../assets/images/companyLogo/Chia.png';
import CHKmate from '../../assets/images/companyLogo/CHKmate.png';
import Coxpe from '../../assets/images/companyLogo/Coxpe.png';
import Drream from '../../assets/images/companyLogo/Drream.png';
import Ejae_Automata from '../../assets/images/companyLogo/Ejae_Automata.png';
import Heize_tech from '../../assets/images/companyLogo/Heize_tech.png';
import HK_Web_Tech from '../../assets/images/companyLogo/HK_Web_Tech.png';
import INE_Bank from '../../assets/images/companyLogo/INE_Bank.png';
import Insights from '../../assets/images/companyLogo/Insights.png';
import Jus2_Consultancy from '../../assets/images/companyLogo/Jus2_Consultancy.png';
import Kept_electronics from '../../assets/images/companyLogo/Kept_electronics.png';
import Klempner_AI from '../../assets/images/companyLogo/Klempner_AI.png';
import Lijo from '../../assets/images/companyLogo/Lijo.png';
import Lsalead from '../../assets/images/companyLogo/Lsalead.png';
import Naiv_Technology from '../../assets/images/companyLogo/naiv_technology.png';
import Netclan from '../../assets/images/companyLogo/Netclan.png';
import NewJeans_Denim from '../../assets/images/companyLogo/NewJeans_Denim.png';
import Onestar from '../../assets/images/companyLogo/Onestar.png';
import Onewe_Chat from '../../assets/images/companyLogo/Onewe_Chat.png';
import Onues from '../../assets/images/companyLogo/Onues.png';
import Ounch_Ltd from '../../assets/images/companyLogo/Ounch_Ltd.png';
import Packvix from '../../assets/images/companyLogo/Packvix.png';
import Preedge_Consultancy from '../../assets/images/companyLogo/Preedge_Consultancy.png';
import Prolet_Firm from '../../assets/images/companyLogo/Prolet_Firm.png';
import Readf from '../../assets/images/companyLogo/Readf.png';
import Sabve from '../../assets/images/companyLogo/Sabve.png';
import Sachi_Retail from '../../assets/images/companyLogo/Sachi_Retail.png';
import Seori_Ltd from '../../assets/images/companyLogo/Seori_Ltd.png';
import SF9_Ltd from '../../assets/images/companyLogo/SF9_Ltd.png';
import Small_Basket from '../../assets/images/companyLogo/Small_Basket.png';
import Solte from '../../assets/images/companyLogo/Solte.png';
import Todo_Manager from '../../assets/images/companyLogo/Todo_Manager.png';
import TripTo_Travels from '../../assets/images/companyLogo/TripTo_Travels.png';
import Triss_academy from '../../assets/images/companyLogo/Triss_academy.png';
import TrueStories_Technology from '../../assets/images/companyLogo/TrueStories_Technology.png';
import Vetle from '../../assets/images/companyLogo/Vetle.png';
import Vivaxel from '../../assets/images/companyLogo/Vivaxel.png';
import Workcroft from '../../assets/images/companyLogo/Workcroft.png';
import Xlp_Technology from '../../assets/images/companyLogo/Xlp_Technology.png';
import Blackcoffer from '../../assets/images/companyLogo/Blackcoffer.png';




import React, { useEffect, useState } from 'react';
import './css/Companies.scss';
import LogoSlider from './LogoSlider';
import axios from 'axios';

function Companies() {
  const logos = [
    BW_Story,
    Netclan,
    Insights,
    Workcroft,
    Lsalead,
    A_plus_Pharma,
    Abira,
    AN_Insurance,
    BAP_Firm,
    Bhavik_Association,
    Bixnov,
    Broklin,
    BTOB_App,
    Byul_Analytics,
    Chia,
    CHKmate,
    Coxpe,
    Drream,
    Ejae_Automata,
    Heize_tech,
    HK_Web_Tech,
    INE_Bank, 
    Jus2_Consultancy,
    Kept_electronics,
    Klempner_AI,
    Lijo,
    Naiv_Technology,
    NewJeans_Denim,
    Onestar,
    Onewe_Chat,
    Onues,
    Ounch_Ltd,
    Packvix,
    Preedge_Consultancy,
    Prolet_Firm,
    Readf,
    Sabve,
    Sachi_Retail,
    Seori_Ltd,
    SF9_Ltd,
    Small_Basket,
    Solte,
    Todo_Manager,
    TripTo_Travels,
    Triss_academy,
    TrueStories_Technology,
    Vetle,
    Vivaxel,
    Xlp_Technology
  ];

  const [avatars, setAvatars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchAvatars = async () => {
      try {
        const response = await axios.get("/api/user/getEmployerAvatarImage");
        setAvatars(response.data.avatars);
        setIsLoading(false)
      } catch (error) {
        console.error("Error fetching avatars:", error);
      }
    };

    fetchAvatars();
  },[]);

  return (
    <div className="home-companies-box1  py-5 px-0">
      <h2 className="title">Top Hiring Companies</h2>
      <div>
      {/* <LogoSlider data={logos} /> */}
      {/* <LogoSlider data={avatars} /> */}
      {isLoading ? (
        <div className="internships-jobs-section" style={{ background: '#F0FBFF', padding: '20px 0' }}></div> // Show a loading indicator while avatars are being fetched
      ) : (
        <LogoSlider data={avatars} />
      )}
    </div>
    </div>
  );
}

export default Companies;
