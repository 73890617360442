import React, { useState, useRef, useEffect } from "react";
import "./css/MatchedJobs.scss";
import filterLogo from "../../assets/images/Group.png";
import circle from "../../assets/images/circle.png";
import money from "../../assets/images/money.png";
import calendar from "../../assets/images/calendar.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Spinner from "../Layout/Spinner";
import { useLocation } from "react-router-dom";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "../../actions/userActions";
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { MdOutlineCheckCircle } from "react-icons/md";
import { State } from "country-state-city";
import PropagateLoader from "react-spinners/PropagateLoader";







function MatchedJobs() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo(0, 0)
  },[]);
 

  const dispatch = useDispatch();
  const categories = [
  { value: 'Accounting & Finance', label: 'Accounting & Finance' },
  { value: 'Advertising', label: 'Advertising' },
  { value: 'Business Service', label: 'Business Service' },
  { value: 'Chemical', label: 'Chemical' },
  { value: 'Civil', label: 'Civil' },
  { value: 'Computer Science', label: 'Computer Science' },
  { value: 'Content Writing ', label: 'Content Writing ' },
  { value: 'Customer Service', label: 'Customer Service' },
  { value: 'Electronics', label: 'Electronics' },
  { value: 'Engineering', label: 'Engineering' },
  { value: 'Health Care', label: 'Health Care' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Manufacturing', label: 'Manufacturing' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Mechanical', label: 'Mechanical' },
  { value: 'Other', label: 'Other' },
  { value: 'Restaurant', label: 'Restaurant' },
  { value: 'Retail', label: 'Retail' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Transportation', label: 'Transportation' },
  ];


  const { state } = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState([1, 100]); // set initial value to 50
  const [loading, setLoading] = useState(true);
  

  // FILTER OPTION :

  const userType = localStorage.getItem('userType');
 


  const [filter, setFilter] = useState({
    title: "",
    category: "",
    location: "",
    opportunityType: "",
    startFrom: "",
    maxDuration: "",
    jobType: [],
    rangeSalary: [0, 50000],
  });

 


  const handleFilter = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      // If the event target is a checkbox, handle its state
      const updatedJobType = [...filter.jobType];
      if (checked) {
        updatedJobType.push(name);
      } else {
        const index = updatedJobType.indexOf(name);
        if (index > -1) {
          updatedJobType.splice(index, 1);
        }
      }

      setFilter((prevFilter) => ({
        ...prevFilter,
        jobType: updatedJobType,
      }));
    } else {
      // For other input types, handle normally
      setFilter({
        ...filter,
        [name]: value,
      });
    }

  };
  useEffect(() => {

    const checkInternshipFlag = sessionStorage.getItem('searchPerformedInternship');

    if (checkInternshipFlag === 'true') {

      const internshipCheckbox = document.getElementById('Internship');
      if (internshipCheckbox) {
        internshipCheckbox.checked = true;
      }
      handleFilter({
        target: {
          name: "Internship",
          type: "checkbox",
          checked: true,
        }
      });

      sessionStorage.removeItem('searchPerformedInternship');
    }

    const checkJobFlag = sessionStorage.getItem('searchPerformedJob');

    if (checkJobFlag === 'true') {

      const jobCheckbox = document.getElementById('Job');
      if (jobCheckbox) {
        jobCheckbox.checked = true;
      }
      handleFilter({
        target: {
          name: "Job",
          type: "checkbox",
          checked: true,
        }
      });

      sessionStorage.removeItem('searchPerformedJob');
    }
  }, []);


  /******************************Filter Option End**********************************/





  // SEARCH OPTION : 

  const [search, setSearch] = useState({
    title: state && state.title? state.title :"",
    location: state && state.location? state.location :"",
  });
 


  

  const handleChange = (e) => {
    setSearch({
      ...search,
      [e.target.name]: e.target.value,
    });
  };


  const handleSearch = async () => {
    setLoading(true);

    const [minSalary, maxSalary] = filter.rangeSalary;

    try {
      const { data } = await axios.post("/api/user/findJobs", {
        title: search.title,
        location: search.location,
        category: filter.category,
        opportunityType: filter.opportunityType,
        startFrom: filter.startFrom,
        maxDuration: filter.maxDuration,
        jobType: filter.jobType,
        rangeSalary: [minSalary, maxSalary],
        page: currentPage, // Add current page
        pageSize: 10, // Display 10 jobs per page
        filter: filter,
      });

  

      const activeJobs = data.jobs.filter(job => job.active === true);


      setLoading(false);
      // setJobData(data.jobs);
      setJobData(activeJobs);
      setTotalPages(data.totalPages); // Update total pages
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data, {
        style: {
          padding: "18px",
        },
      });
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  /*************************Search Option End********************************/



  const handleSliderChange = (event, newValue) => {
    // Update the rangeSalary filter based on slider values
    setFilter({
      ...filter,
      rangeSalary: newValue,
    });
  };


  const dropdownMin = (value) => {
    // Ensure that the minimum value is not greater than the current maximum value
    const newMinValue = Math.min(value, filter.rangeSalary[1]);
  
    // Update the rangeSalary filter based on slider values
    setFilter({
      ...filter,
      rangeSalary: [newMinValue, filter.rangeSalary[1]],
    });
  };
  
  const dropdownMax = (value) => {
    // Ensure that the maximum value is not less than the current minimum value
    const newMaxValue = Math.max(value, filter.rangeSalary[0]);
  
    // Update the rangeSalary filter based on slider values
    setFilter({
      ...filter,
      rangeSalary: [filter.rangeSalary[0], newMaxValue],
    });
  };
  
  // Add a new function to handle the slider's "commit" event
  const handleSliderCommit = () => {
    // This will ensure the filtering occurs when the user releases the slider handle
    handleSearch();
  };

  const stepValues = Array.from({ length: 11 }, (_, i) => i * 10 * 1000);

  const formatNumberInK = (value) => {
    if (value >= 1000) {
      return `${(value / 1000).toFixed(1)}k`;
    }
    return value.toString();
  };

  const generateStepLabels = () => {
    return stepValues.map((value) => ({
      value,
      label: "",
    }));
  };

  const filtersBox = useRef(null);

  const toggleFiltersBox = () => {
    filtersBox.current.classList.toggle("open");
  };



  const { user } = useSelector((state) => state.user);

  const [jobData, setJobData] = useState("")
  const [orgData, setOrgData] = useState("")
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filled, setFilled] = useState(false);


  const getJobData = async () => {
      try {
        const { data } = await axios.get("/api/user/getAllJobs");
        const jobD = data.job
        setJobData(jobD)
      } catch (error) {
      }
  }


  const getFilterData = async () => {
    try {
      const { data } = await axios.post("/api/user/findJobs", { filter });
      navigate('/matchedJobs', { state: data.jobs })
    } catch (error) {
      toast.error(error.response.data);
    }
  }

  useEffect(() => {
    handleSearch();
  }, [])


  useEffect(() => {
  //  getJobData();
    handleSearch();


    if (filter.category && filter.category) {
      getFilterData();

    }
  }, [filter, currentPage])

  // const hearticonclick = () => {
  //   setFilled(!filled);
  // };

  const handleSaveJob = async (jobId) => {
    try {

      const { data } = await axios.get(`/api/user/saveJob/${jobId}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });
      if (data.success) {
        setFilled(!filled);
        window.location.reload();

      }
    } catch (error) {

    }
  };

  useEffect(() => {
    dispatch(loadUser());
  }, []);
  // const iconStyle = {
  //   position: 'absolute',
  //   top: '10px',
  //   right: '10px',
  //   fontSize: '18px',
  //   color: filled ? 'red' : '',
  //   cursor: 'pointer'
  // };

  const handleRemoveJob = async (jobId) => {
    try {
      const { data } = await axios.get(`/api/user/removeJob/${jobId}`, {
        headers: {
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      });

      if (data.success) {
        setFilled(prevFilled => !prevFilled);
         window.location.reload();

      }
    } catch (error) {
    }
  };


 



  return (


    <>
      <div className="container-fluid matched-jobs "  style={{marginTop: "-30px"}}>

        <div className="jobbox container " >
          <div className="row " >
            <div className=" col-lg-4 col-md-4 col-sm-12 mx-auto" style={{ marginTop: "84px", maxWidth: "400px" }}>
              <div className="filterbox  pb-0">
                <div className="filter-title text-center ">
                  <span className="mx-3">
                    <img src={filterLogo} alt="Account" style={{width: "25px"}}/> Filters
                  </span>
                </div>
                <div class="form-check my-4 text-center">
                 {/* <input class="form-check-input mt-2" type="checkbox" />*/}
                  <label class="form-check-label " for="defaultCheck1" style={{marginRight: '25px'}}>
                    As per my
                    <a
                      className="mx-2"
                      href="#"
                      style={{ color: "#4B92c8", textDecoration: "none" }}
                    >
                      preferences
                    </a>
                  </label>
                </div>
                <div className="filterboxoption my-5">
                  <div class="form-group my-4" >
                    <label htmlFor="category" style={{ fontSize: "14px" }}>
                      Category
                    </label>
                    <Select
                      options={categories}
                      isClearable={true}
                      placeholder={<div style={{ fontSize: '12px' }}>Category</div>}
                      onChange={(selectedOption) => {
                        setFilter({ ...filter, category: selectedOption ? selectedOption.value : '' });
                      }}
                      value={categories.find((c) => c.value === filter.category)}
                    />
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div class="form-group ">
                        <label for="exampleFormControlInput1" style={{ fontSize: "14px" }}>Start from</label>
                        <input
                          style={{ fontSize: "13px" }}
                          type="date"
                          class="form-control py-3"
                          placeholder="Start Date"
                          name="startFrom"
                          onChange={handleFilter}
                          value={filter.startFrom}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div class="form-group ">
                        <label for="exampleFormControlInput1" style={{ fontSize: "14px" }}>Max.duration</label>
                        <input
                          style={{ fontSize: "13px" }}
                          type="number"
                          class="form-control py-3"
                          placeholder="Months"
                          name="maxDuration"
                          onChange={handleFilter}
                          value={filter.maxDuration}
                        />
                      </div>
                    </div>
                  </div>
                  <label for="exampleFormControlInput1" style={{ fontSize: "14px", marginTop: "10px" }}>Job Type</label>
                  <div className="row my-2">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div class="form-check">
                        <input class="form-check-input mt-2" type="checkbox" id="Part-Time" name="Part-Time" onChange={handleFilter} checked={filter.jobType["Part-Time"]} />
                        <label class="form-check-label mx-2" htmlFor="Part-Time">
                          Part time
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div class="form-check">
                        <input class="form-check-input mt-2" type="checkbox" id="Internship" name="Internship" onChange={handleFilter} checked={filter.jobType["Internship"]} />
                        <label class="form-check-label mx-2" htmlFor="Internship">
                          Internship
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row my-2">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div class="form-check">
                        <input class="form-check-input mt-2" type="checkbox" id="Full-Time" name="Full-Time" onChange={handleFilter} checked={filter.jobType["Full-Time"]} />
                        <label class="form-check-label mx-2" htmlFor="Full-Time">
                          Full Time{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div class="form-check">
                        <input class="form-check-input mt-2" type="checkbox" id="Job" name="Job" onChange={handleFilter} checked={filter.jobType["Job"]} />
                        <label class="form-check-label mx-2" htmlFor="Job">
                          Job{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <label for="exampleFormControlInput1" style={{ fontSize: "14px", marginTop:" 20px" }}> Range Salary (₹)</label>       
                  <Slider
                    value={filter.rangeSalary}
                    onChange={handleSliderChange}
                    onChangeCommitted={handleSliderCommit}
                    onBlur={handleSliderCommit}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    min={0}
                    max={100 * 1000}
                    step={10 * 1000}  // Adjust this to match your desired interval
                    marks={generateStepLabels()}
                    className = 'ml-1'
                    style={{width: '100%'}}
                  />

               { /*  <p style={{ fontSize: "13px", fontWeight: "500" }}>
                    Selected Salary - ₹ {formatNumberInK(filter.rangeSalary[0])} to ₹ {formatNumberInK(filter.rangeSalary[1])}
                    </p> */}

                  <div style={{display: 'flex', flexWrap: 'wrap'}}>

                    <select class="form-select" aria-label="Default select example"  style={{width: "100px", fontSize: '14px', height: '30px'}}
                     value={filter.rangeSalary[0]}
                     onChange={(e) => dropdownMin(e.target.value)}
                    >
                      <option value={0}>₹ 0</option>
                      <option value={10000}>₹ 10000</option>
                      <option value={20000}>₹ 20000</option>
                      <option value={30000}>₹ 30000</option>
                      <option value={40000}>₹ 40000</option>
                      <option value={50000}>₹ 50000</option>
                      <option value={60000}>₹ 60000</option>
                      <option value={70000}>₹ 70000</option>
                      <option value={80000}>₹ 80000</option>
                      <option value={90000}>₹ 90000</option>
                      <option value={100000}>₹ 100000</option>
                    </select>

                    <p  style={{marginLeft: "15px", marginRight: '15px', marginTop: '3px', color: "gray"}}>to</p>

                    <select class="form-select" aria-label="Default select example"  style={{width: "100px", fontSize: '14px', height: '30px'}} 
                     value={filter.rangeSalary[1]}
                     onChange={(e) => dropdownMax(e.target.value)}
                    >
                     <option value={0}>₹ 0</option>
                      <option value={10000}>₹ 10000</option>
                      <option value={20000}>₹ 20000</option>
                      <option value={30000}>₹ 30000</option>
                      <option value={40000}>₹ 40000</option>
                      <option value={50000}>₹ 50000</option>
                      <option value={60000}>₹ 60000</option>
                      <option value={70000}>₹ 70000</option>
                      <option value={80000}>₹ 80000</option>
                      <option value={90000}>₹ 90000</option>
                      <option value={100000}>₹ 100000</option>
                    </select>

                      </div>


                </div>
              </div>
            </div>




            <div className="col-lg-8 col-md-8 col-sm-12" style={{ maxWidth: "900px" }}>
              <div className="fixed-header">
                <h2 className="title my-5">Jobs/Internships matching to your profile</h2>
                <div className="alljobpage">
                  <div className="row pl-2 pr-2" >
                    <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: "0" }}>
                      <div className="alljob1">
                        <div className="input-box border" style={{ backgroundColor: "white" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-search"
                          >
                            <circle cx={11} cy={11} r={8} />
                            <line x1={21} y1={21} x2="16.65" y2="16.65" />
                          </svg>

                          <input
                            type="text"
                            placeholder="Search job title or keyword"
                            style={{ border: "none" }}
                            name="title"
                            id="title"
                            onChange={handleChange}
                            value={search.title}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12" style={{ padding: "0" }}>
                      <div className="alljob1" >
                        <div className="input-box border" style={{ backgroundColor: "white" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={18}
                            height={18}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-map-pin"
                          >
                            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" />
                            <circle cx={12} cy={10} r={3} />
                          </svg>
                          <input type="text"
                            placeholder="Country or location"
                            style={{ border: "none", width: "220px" }}
                            name="location"
                            id="location"
                            onChange={handleChange}
                            value={search.location}
                          />
                          <button onClick={handleSearch} className="btn btnsearch position-absolute   end-0  end-0" style={{ marginTop: "7px", marginRight: "15px", height: "30px" }}>Search</button>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              </div>

              {loading ? (<>
              
                    <div className="my-auto"  style={{ textAlign: 'center' }}>
                            <p style={{ color: "#4B92C8", fontWeight: "500", marginTop: '170px', fontSize: "20px", marginLeft: '43px', marginBottom: "5px" }}>Loading..</p>
                            <PropagateLoader
                                color={'#4B92C8'}
                                loading={true}
                                size={15}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                                style={{marginLeft: "27px"}}
                            />
                        </div>
          

              </>) : (<>

              {jobData && jobData.length != 0 ? (<>


                <div className="content-below-header">
                  <div className="alljobdetails pt-5 pb-2">
                    <h3 className="titles">{jobData.length} Job results</h3>
                    {((jobData).slice().reverse()).map((data, index) => {


                      return <>

                        <div className="cards my-4">

                          <div className="card py-4 px-4">
                            <div className="cardHeader">

                              {/* <FontAwesomeIcon icon={faHeart} style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                fontSize: '18px',
                                color: user&&user.saveJob.includes(data._id) ? 'red' : '',
                                cursor: 'pointer'
                              }} onClick={() => handleSaveJob(data._id)} /> */}

                              {/* 
                              <FontAwesomeIcon
                                icon={user && user.saveJob.includes(data._id) ? faHeart : faHeart}
                                style={{
                                  position: 'absolute',
                                  top: '10px',
                                  right: '10px',
                                  fontSize: '18px',
                                  color: user && user.saveJob.includes(data._id) ? 'red' : '',
                                  cursor: 'pointer'
                                }}
                                
                                onClick={() => {
                                  if (user && user.saveJob.includes(data._id)) {
                                    handleRemoveJob(data._id);
                                  } else {
                                    handleSaveJob(data._id);
                                  }
                                }}
                              /> */}
          
                                 {userType && userType == 'User'? (<>
                                <AiFillHeart
                                style={{
                                  position: 'absolute',
                                  top: '-5px',
                                  right: '10px',
                                  fontSize: '25px',
                                  color: user && user.saveJob.includes(data._id) ? 'red' : 'white',
                                  cursor: 'pointer',
                                  marginTop: "10px",
                                }}
                                onClick={() => {
                                  if (user && user.saveJob.includes(data._id)) {
                                    handleRemoveJob(data._id);
                                  } else {
                                    handleSaveJob(data._id);
                                  }
                                }}
                              />

                              <AiOutlineHeart
                                style={{
                                  position: 'absolute',
                                  top: '-5px',
                                  right: '10px',
                                  fontSize: '25px',
                                  color: user && user.saveJob.includes(data._id) ? 'red' : 'black', // Define outline color
                                  cursor: 'pointer',
                                  marginTop: "10px",
                                }}
                                onClick={() => {
                                  if (user && user.saveJob.includes(data._id)) {
                                    handleRemoveJob(data._id);
                                  } else {
                                    handleSaveJob(data._id);
                                  }
                                }}
                              />
                              </>):(<></>)}


                           

                              <div className="row py-1 ">
                                <div className="col-lg-1 col-md-1 col-sm-1">
                                  <img
                                    src={data.organisationAvatar}
                                    alt="company"
                                    style={{
                                      height: "50px",
                                      width: "50px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => navigate('/preview', { state: data })}
                                  />
                                </div>

                                <div className="col-lg-11 col-md-11 col-sm-11">
                                  <h3 onClick={() => navigate('/preview', { state: data })}   style={{ fontSize: "18px", cursor: 'pointer', width: "60%" }} className="cname mt-2 mb-2">{data.profile}</h3>
                                  <p className="sub">

                                    <span className="name mr-3" style={{ fontSize: "14px" }} >{data.organisationName}</span>
                                  
                         
                                      <button className="post001 mr-4">{data.isPartTimeAllowed === 'yes' || data.isPartTimeAllowed === 'Yes' || data.isPartTimeAllowed === 'YES' ?
                                        (<>Part-Time</>) : (<>Full-Time</>)
                                      }</button>
                                
                                      <button className="post001 mr-4">
                                        {data.opportunityType}
                                      </button>
                                 
                                    {/* <span className="mx-2">
                                      <button className="btn worktype">
                                        {data.jobType === 'Remote' ?
                                          (<>Work From Home</>) : (<>{data.city}</>)
                                        }

                                      </button>
                                    </span> */}

                                   
                                      <button className="post001 mr-4">
                                        {data.jobType === 'Remote' ? (
                                          <>Work From Home</>
                                        ) : (
                                          <>
                                            {Array.isArray(data.city) ? (
                                              data.city.map((city, index) => (
                                                <span key={index}>
                                                  {city}
                                                  {index !== data.city.length - 1 && ', '}
                                                </span>
                                              ))
                                            ) : (
                                              <>{data.city}</>
                                            )}


                                          </>

                                        )}
                                      </button>
                                  
                                    {data.jobType && (
                                 
                                      <button className="post001 mr-4">
                                          {data.jobType}
                                        </button>
                              
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="row py-4 px-3">
                              <div className="col-lg-3 col-md-3 col-sm-4">
                                <p>
                                  <span>
                                    <img src={circle} alt="play" />
                                  </span>
                                  <span className="mx-3 heeading">START DATE</span>
                                </p>
                                <h3 style={{ fontSize: "14px" }} className="head ml-2">{(data.startDate).slice(8, 10) + "-" + (data.startDate).slice(5, 7) + "-" + (data.startDate).slice(0, 4)}</h3>
                              </div>


                              {/* <div className="col-lg-3 col-md-3 col-sm-4">
                                <p>
                                  <span>
                                    <img src={calendar} alt="play" />
                                  </span>
                                  <span className="mx-3 heeading">DURATION</span>
                                </p>
                                <h3 style={{ fontSize: "14px" }} className="head ml-2">{data.duration} Months</h3>
                              </div> */}


                              {data.opportunityType !== "Job" && (
                                <div className="col-lg-3 col-md-3 col-sm-4">
                                  <p>
                                    <span>
                                      <img src={calendar} alt="play" />
                                    </span>
                                    <span className="mx-3 heeading">DURATION</span>
                                  </p>
                                  <h3 style={{ fontSize: "14px" }} className="head ml-2">{data.duration} Months</h3>
                                </div>
                              )}

                              <div className="col-lg-6 col-md-6 col-sm-4">
                                <p>
                                  <span>
                                    <img src={money} alt="play" />
                                  </span>
                                  <span className="mx-3 heeading">STIPEND/SALARY</span>
                                </p>
                                {data.salary == 0 ? (<>
                                  <h3 style={{ fontSize: "14px" }} className="head ml-2">Unpaid</h3>
                                </>) : (<>
                                  <h3 style={{ fontSize: "14px" }} className="head ml-2">₹{data.salary}/month</h3>
                                </>)}
                              </div>
                            </div>
                            <hr></hr>
                            <div className="cardbutton ">
                              <div className="ml-auto text-end"  style={{display: "flex", flexWrap: 'wrap'}}>
                                  <button onClick={() => navigate('/preview', { state: data })} className="btn btnview ml-auto" style={{width: "100px", height: '30px'}}> View Details</button>
                                  {user?           
                                userType !== 'Employer' && userType !== null && (
                                    data.userApplied.includes(user? user._id: "") ?  (<>
                                      <p style={{color: 'green', fontWeight: "400",marginTop: "4px", width: "100px", marginLeft: '-10px', marginRight: '10px' }}><MdOutlineCheckCircle style={{marginRight: '3px', fontSize: "18px", marginTop: "-2px"}}/>Applied</p>
                                    </>):(<>
                                      <button onClick={() => navigate('/coverletterpage', { state: data })} className="btn btnapply"  style={{width: "100px", marginLeft: '20px'}}>Apply Now</button>
                                    </>)    
                                ):(<>
                                  <div style={{ textAlign: 'center' }}>
                                  <button onClick={() => navigate('/login')} className="btn btnapply"  style={{width: "100px", marginLeft: '20px'}}>Apply Now</button>
                                 </div>
                                 </>)
                                     }







                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    })
                    }
                  </div>
                </div>

                <div className="pagination">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    Next
                  </button>
                </div>


              </>) : (<>
                <div className="text-center mt-5">
                  <i class="fa fa-window-close-o mx-auto" aria-hidden="true" style={{ fontSize: '200px' }}></i>
                  <h1>Job not Found</h1>
                </div>
              </>)}
              </>)}
            </div>
          </div>
        </div>

        {/* <div className="jobs-box">
        <div className="filters-box" ref={filtersBox}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            onClick={toggleFiltersBox}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x close_btn"
          >
            <line x1={18} y1={6} x2={6} y2={18} />
            <line x1={6} y1={6} x2={18} y2={18} />
          </svg>
          <div className="filters">
            <div className="filter">
              <input type="checkbox" name="workFromHome" id="workFromHome" />
              <label htmlFor="workFromHome">Work From Home</label>
            </div>
            <div className="filter">
              <input
                type="checkbox"
                name="workFromOffice"
                id="workFromOffice"
              />
              <label htmlFor="workFromHome">Work From Office</label>
            </div>
            <div className="filter">
              <input type="checkbox" name="partTime" id="partTime" />
              <label htmlFor="partTime">Part Time</label>
            </div>
            <div className="filter">
              <input type="checkbox" name="fullTime" id="fullTime" />
              <label htmlFor="fullTime">Full Time</label>
            </div>
            <div className="filter">
              <input type="checkbox" name="fresherJobs" id="fresherJobs" />
              <label htmlFor="fresherJobs">Fresher Jobs</label>
            </div>
            <div className="filter">
              <input
                type="checkbox"
                name="allInternships"
                id="allInternships"
              />
              <label htmlFor="fresherJobs">Including All Internships</label>
            </div>

            <div className="input-box">
              <label htmlFor="category">Category</label>
              <input type="text" name="category" id="category" />
            </div>
            <div className="input-box">
              <label htmlFor="location">Location</label>
              <input type="text" name="location" id="location" />
            </div>
            <div className="input-box">
              <label htmlFor="keyword">Search By Keyword</label>
              <input type="text" name="keyword" id="keyword" />
            </div>
          </div>
        </div>

        <div className="jobs">
          <div className="job-card">
            <h3 className="company-title">BlackCoffer</h3>
            <div className="job-title">Front-end Developer</div>
            <div className="job-title">Work From Home</div>
            <div className="info-box">
              <div className="info">
                <strong>Start Date</strong>
                <span className="status">28-02-2023</span>
              </div>
              <div className="info">
                <strong>CTC</strong>
                <span className="status"></span>
              </div>
            </div>

            <div className="options">
              <a href="/">View Details</a>
              <a href="/" className="btn btn-primary">
                Apply Now
              </a>
            </div>
          </div>
          <div className="job-card">
            <h3 className="company-title">BlackCoffer</h3>
            <div className="job-title">Front-end Developer</div>
            <div className="job-title">Work From Home</div>
            <div className="info-box">
              <div className="info">
                <strong>Start Date</strong>
                <span className="status">28-02-2023</span>
              </div>
              <div className="info">
                <strong>CTC</strong>
                <span className="status"></span>
              </div>
            </div>

            <div className="options">
              <a href="/">View Details</a>
              <a href="/" className="btn btn-primary">
                Apply Now
              </a>
            </div>
          </div>
          <div className="job-card">
            <h3 className="company-title">BlackCoffer</h3>
            <div className="job-title">Front-end Developer</div>
            <div className="job-title">Work From Home</div>
            <div className="info-box">
              <div className="info">
                <strong>Start Date</strong>
                <span className="status">28-02-2023</span>
              </div>
              <div className="info">
                <strong>CTC</strong>
                <span className="status"></span>
              </div>
            </div>

            <div className="options">
              <a href="/">View Details</a>
              <a href="/" className="btn btn-primary">
                Apply Now
              </a>
            </div>
          </div>
          <div className="job-card">
            <h3 className="company-title">BlackCoffer</h3>
            <div className="job-title">Front-end Developer</div>
            <div className="job-title">Work From Home</div>
            <div className="info-box">
              <div className="info">
                <strong>Start Date</strong>
                <span className="status">28-02-2023</span>
              </div>
              <div className="info">
                <strong>CTC</strong>
                <span className="status"></span>
              </div>
            </div>

            <div className="options">
              <a href="/">View Details</a>
              <a href="/" className="btn btn-primary">
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div> */}

      </div>
    </>
  );
}

export default MatchedJobs;
